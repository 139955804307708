/* eslint-disable indent */
import type { ReactNode } from 'react';

import { css, media, mixins, styled, typography } from '@pulse-web-ui/theme';

import { Alignment } from '@src/pages/authorization/authorization.types';

type GridProps = {
  marginBottom?: number;
  gridStep?: number;
};

type FullWidthProps = {
  spaceBetween?: boolean;
};

type OrderHeaderProps = {
  bgUrl?: string | ReactNode;
};

type UniversalColumnProps = {
  align?: string;
  bgColor?: string;
  gap?: number;
};

type TextSubProps = {
  isError?: boolean;
  adaptiveCentered?: boolean;
  align?: 'left' | 'center' | 'right';
  margin?: string;
};

export const Container = styled.div<{
  margin?: string;
}>`
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  width: 100%;
`;

export const IflSumPerMonthContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const IflSumPerPeriodContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const GridColumnContainer = styled.div<GridProps>`
  ${(props) => mixins.flex({ horizontal: false, gap: props.gridStep || 32 })}
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

export const CheckboxGridItemWrapper = styled.div`
  align-items: flex-start;
  height: 32px;
  ${media.desktop} {
    padding-top: 20px;
    height: 56px;
  }
`;

export const AdaptiveListWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 16, wrap: false })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: true })}
  }
`;

export const CheckboxGridItemWrapperWithoutTopPadding = styled.div<{
  height?: number;
}>`
  align-items: flex-start;
  height: 32px;
  ${media.desktop} {
    height: ${({ height }) => (height ? `${height}px` : '56px')};
  }
`;

export const MiddleNameWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8, wrap: false })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: true })}
  }
`;

export const FullWidthWrapper = styled.div<FullWidthProps>`
  ${(props) =>
    props.spaceBetween
      ? mixins.flex({ horizontal: true, justifyContent: 'space-between' })
      : null}
  width: 100%;
`;

export const WrapperWithFlexibleGrow = styled.div`
  flex-grow: 1;
`;

export const AdaptiveListExtendedWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 16 })}
  margin-bottom: 56px;
`;

export const PaymentPeriodsListWrapper = styled.div`
  ${mixins.flex({ horizontal: false, gap: 8 })}
  margin-bottom: 24px;
`;
export const PromoWrapper = styled.div`
  margin-bottom: 32px;
`;

export const AdaptiveSliderListWrapper = styled.div<{ marginBottom?: number }>`
  ${mixins.flex({ horizontal: false, gap: 16 })}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`;

export const AdaptiveHeadersWrapper = styled.div`
  margin-bottom: 32px;
`;

export const NumericInputWrapper = styled.div`
  margin-top: 32px;

  ${media.desktop} {
    margin-top: 24px;
  }
`;

export const NumericInputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
`;

export const AdaptiveContainer = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 32,
  })};
`;

export const AdaptiveContainerInverse = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 32,
  })};

  ${media.desktop} {
    gap: 0;

    ${mixins.flex({
      horizontal: false,
      reverse: true,
      gap: 0,
    })}
    & > div {
      margin-bottom: 8px;
    }

    & > :first-child {
      margin-bottom: 8px;
    }
  }
`;

export const CenterAlignWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
    gap: 16,
    justifyContent: 'center',
  })}
`;

export const AlignedWrapper = styled.div`
  ${mixins.flex({
    verticalAlignment: 'center',
    gap: 16,
    justifyContent: 'flex-start',
  })}
`;

export const CenteredWrapper = styled.div<{
  alignment?: Alignment;
}>`
  ${({ alignment }) =>
    mixins.flex({
      alignItems: alignment ? alignment : 'center',
      horizontal: false,
    })}
  text-align: center;
`;

export const NumericInputContainer = styled.div`
  margin-bottom: 18px;
`;

export const ControllerContainer = styled.div<{
  margin?: string;
}>`
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const NoWrapItem = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

export const AdaptiveColumns = styled.div<{ marginBottom?: number }>`
  ${mixins.flex({ horizontal: false, gap: 16 })}
  margin-bottom: ${({ marginBottom }) =>
    marginBottom !== undefined ? `${marginBottom}px` : '16px'};

  ${media.desktop} {
    ${mixins.flex({ horizontal: true })}
  }
}

& > div {
  ${media.desktop} {
    flex-basis: calc(50% - 8px);
  }
}
`;

export const UniversalColumnsWrapper = styled.div`
  width: 100%;
  ${mixins.flex({ horizontal: false, gap: 24 })}
  ${media.desktop} {
    ${mixins.flex({ horizontal: true, wrap: false })}
  }
`;

export const UniversalColumn = styled.div<UniversalColumnProps>`
  &:last-child {
    margin-bottom: 24px;
  }
  ${(props) => mixins.flex({ horizontal: false, gap: props.gap })}
  box-sizing: border-box;
  width: 100%;
  text-align: ${(props) => props.align};
  background-color: ${(props) =>
    props.theme.common?.outlined ? 'transparent' : props.bgColor};
  padding: ${(props) => (props.bgColor ? '16px 24px' : 0)};
  border-radius: ${(props) =>
    props.bgColor ? props.theme.common?.radius || '8px' : 0};
  color: ${(props) => props.theme.colors.text.primary};

  ${media.desktop} {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ bgColor, theme }) =>
    bgColor &&
    theme.common?.outlined &&
    css`
      border: 1px solid ${theme.colors.neutral[10]};
    `}
`;

export const OrderHeader = styled.div<OrderHeaderProps>`
  padding: 48px 16px 32px;
  background-size: cover;
  background-image: ${(props) =>
    props.bgUrl ? `url(${props.bgUrl})` : 'none'};
  border-radius: 16px;
`;

export const OrderTitle = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  ${typography.medium24}
  line-height: 34px;
  margin-bottom: 8px;
`;

export const OrderSubTitle = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};

  ${typography.regular16}
  line-height: 20px;
`;

export const OrderWrapper = styled.div`
  margin-bottom: 25px;
`;

export const DocumentsWrapper = styled.div`
  margin-top: 8px;

  > div {
    padding-bottom: 0;
    margin-bottom: 16px;
  }
`;

export const WarnigWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral[10]};
  ${typography.regular18}
  ${mixins.flex({ horizontal: true, gap: 6, wrap: false })}
  color: ${(props) => props.theme.colors.text.primary};
  border-radius: 8px;
  padding: 12px 18px;
  box-sizing: border-box;
  line-height: 20px;
  width: 100%;
  & > svg {
    flex-shrink: 0;
  }
`;

export const InfoWrapper = styled.div`
  ${typography.regular16}
  ${mixins.flex({ horizontal: true, gap: 6, wrap: false })}
  color: ${(props) => props.theme.colors.text.primary};
  border-radius: ${({ theme }) => theme.common.radius};
  background: ${({ theme }) => theme.colors.surface.background};
  padding: 12px 16px;
  & > svg {
    flex-shrink: 0;
  }
`;

export const TextSub3 = styled.div<TextSubProps>`
  ${typography.pulsSubhead4.regular}
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
  color: ${({ theme, isError }) =>
    isError ? theme.colors.text.error : theme.colors.text.primary};
  margin: ${(props) => (props.margin ? props.margin : '7px 0 32px')};
  letter-spacing: 0;
  ${media.desktop} {
    text-align: ${(props) => (props.adaptiveCentered ? 'center' : 'initial')};
  }
`;

export const WarnigText = styled.div`
  padding: 3px 0;
`;

export const LinkContainer = styled.div`
  padding-top: 24px;
  ${media.desktop} {
    padding-top: 32px;
    padding-bottom: 8px;
  }
`;

export const OrderWrapperTitle = styled.h2`
  ${typography.medium20}
  color: ${({ theme }) => theme.colors.text.primary};
  letter-spacing: 0;
`;

export const RiskWrapper = styled.div`
  width: 100%;

  ${media.desktop} {
    width: calc(50% - 8px);
  }
`;

export const PageSpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  ${mixins.flex({
    horizontal: false,
    alignItems: 'center',
    justifyContent: 'center',
  })}
`;

export const ButtonWrapper = styled.div`
  ${mixins.flex({
    justifyContent: 'flex-start',
  })}
`;

export const MapContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
`;

export const StyledDaDataContainer = styled.div<{ error?: boolean }>`
  .react-dadata__container {
    position: relative;
  }

  .react-dadata__suggestions {
    position: absolute;
    list-style: none;
    margin: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border: 1px solid ${({ theme }) => theme.colors.neutral[50]};
    box-shadow: ${({ theme }) => theme.common['box-shadow']};
    border-radius: 4px;
    top: calc(100% + 8px);
    padding: 8px 0;
    overflow: hidden;
    left: 0;
    right: 0;
    text-align: left;
  }

  .react-dadata__suggestion-note {
    ${typography.caption1.regular}
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 10px 10px 5px 10px;
  }

  .react-dadata__suggestion {
    ${typography.regular18}
    padding: 10px 24px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    display: block;
    background: none;
    border: none;
    text-align: left;
  }

  .react-dadata__suggestion--line-through {
    text-decoration: line-through;
  }

  .react-dadata__suggestion-subtitle {
    ${typography.caption1.regular}
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .react-dadata__suggestion-subtitle-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 4px;
  }

  .react-dadata__suggestion-subtitle-item:last-child {
    margin-right: 0;
  }

  .react-dadata__suggestion--current {
    background-color: ${({ theme }) => theme.colors.neutral[20]};
  }

  .react-dadata__suggestion:hover {
    background-color: ${({ theme }) => theme.colors.neutral[20]};
  }

  .react-dadata mark {
    background: none;
  }

  .react-dadata--highlighted {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const SubHeadersWrapper = styled.div`
  padding-top: 8px;
`;

export const CalendarContainer = styled.div`
  .react-datepicker-popper {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;

    ${media.desktop} {
      width: 350px;
      padding: 0;
      padding-bottom: 10px;
    }
  }

  .react-datepicker {
    width: 100%;

    ${media.desktop} {
      width: 350px;
    }
  }

  .react-datepicker__day-name {
    text-transform: capitalize;
  }
`;
