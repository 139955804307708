/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format } from 'date-fns';
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Checkbox } from '@pulse-web-ui/checkbox';
import { ReactDatePicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';

import {
  Container,
  FormLabel,
  FormSub,
  NumericInputBox,
  NumericInputLabel,
  NumericInputWrapper,
  Skeleton,
  UserAgreement,
} from '@src/components';
import { CalendarContainer } from '@src/components/container';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  PRODUCT_VERSION_ONE,
  analyticEvents,
  insurancePersonDefaultData,
  insuranceProductsCode,
  sportNsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest } from '@src/hooks';
import {
  AuthActionTypes,
  SportNSActionTypes,
  Store,
  WizardActionTypes,
} from '@src/store';
import { InsuranceProductDateType } from '@src/store/sport/sport-ns-store.types';
import { InsurePerson } from '@src/types';
import { addTestAttribute, convertDateFormatInTimeZone } from '@src/utils';

import { useSportDraft } from './hooks';
import {
  StyledCheckboxBox,
  StyledDateText,
  StyledDateTextMobile,
  StyledDatepicker,
  StyledDatepickerBox,
  StyledNumericInput,
  StyledText,
} from './sport-form.styles';
import { getDays, getSportOptions } from './utils';

export const FormSportNSQuantity = (): ReactElement => {
  const maxDateCalendarRef = useRef<
    ReactDatePicker & { setOpen: (open: boolean) => void }
  >(null);

  const {
    state: {
      stateFormNSSport: {
        numberInsurePersons,
        insurePersons,
        insuranceProduct,
        insuranceProductDate,
        selectedStartDate,
        selectedEndDate,
        policyOnYear,
        presetData,
      },
      stateWizard: { wantNextStep, updateFormState },
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const [selectPersonsError, setSelectPersonsError] = useState(false);
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndStartDateError] = useState<string>('');

  const {
    res: insuranceLimitRes,
    isLoading,
    error,
    refetch,
  } = useRequest(
    'formSportNSGetLimitInsuranceProducts',
    'get',
    `/v3/references/insurance-limit-start/${
      insuranceProductsCode.sport
    }/${PRODUCT_VERSION_ONE}${
      presetData?.legalCode ? `?legalCode=${presetData.legalCode}` : ''
    }`,
    {},
    [presetData?.legalCode]
  );

  const sportSchema = yup.object({
    minDate: yup
      .string()
      .required(t('SPORT_FORM:errors.startDateOfPolicy') || '')
      .nullable(),
    maxDate: yup
      .string()
      .required(t('SPORT_FORM:errors.policyExpirationDate') || '')
      .test(
        'maxDuration',
        t('SPORT_FORM:errors.dateGreaterMaxDuration', {
          maxDuration: insuranceLimitRes?.maxDuration,
        }) || '',
        (value, ctx) => {
          const dateEnd = new Date(value || '');
          const maxDurationDate = new Date(
            ctx?.parent?.minDate || insuranceLimitRes?.startDate
          );
          maxDurationDate.setDate(
            maxDurationDate.getDate() + insuranceLimitRes?.maxDuration
          );

          return dateEnd < maxDurationDate;
        }
      )
      .nullable(),
    policyOnYear: yup.boolean(),
  });

  const storeInsuranceProductDate = useCallback(
    (data: InsuranceProductDateType) => {
      dispatch({
        type: SportNSActionTypes.SetInsuranceProductDate,
        payload: data || undefined,
      });
    },
    [insuranceProduct]
  );

  useEffect(() => {
    if (!isLoading && !insuranceProductDate) {
      storeInsuranceProductDate(insuranceLimitRes);
    }
  }, [isLoading]);

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm<any>({
    resolver: yupResolver(sportSchema),
    mode: 'all',
    defaultValues: {
      numberInsurePersons: numberInsurePersons,
      minDate: selectedStartDate,
      maxDate: selectedEndDate,
      policyOnYear: policyOnYear,
    },
  });

  const clearDateHandler = () => {
    dispatch({
      type: SportNSActionTypes.SetInsuranceProductStartDate,
      payload: null,
    });
    dispatch({
      type: SportNSActionTypes.SetInsuranceProductEndDate,
      payload: null,
    });
    reset({
      minDate: null,
      maxDate: null,
    });
  };

  const maxDate = getValues('maxDate');
  const minDate = getValues('minDate')
    ? new Date(getValues('minDate'))
    : undefined;

  const storeNumberInsurePersons = useCallback((data) => {
    const newInsurePersons: InsurePerson[] = [];
    const { numberInsurePersons } = data;
    for (let i = 0; i < numberInsurePersons; i++) {
      newInsurePersons.push(insurancePersonDefaultData);
    }
    dispatch({
      type: SportNSActionTypes.SetNumberInsurePersons,
      payload: numberInsurePersons,
    });

    dispatch({
      type: SportNSActionTypes.SetInsurePersons,
      payload: newInsurePersons,
    });
  }, []);

  const handleOnChangeMinDate = useCallback(
    (onChange: (...event: any[]) => void) =>
      (date: Date, ...other: any[]) => {
        // Если полис на год, пропускаем открытие календаря, т.к. даты уже просталены.
        if (policyOnYear) return onChange(date, ...other);

        setValue('minDate', date, { shouldValidate: true, shouldTouch: true });
        setTimeout(() => {
          if (maxDateCalendarRef?.current?.setOpen && !maxDate) {
            maxDateCalendarRef.current.setOpen(true);
            maxDateCalendarRef.current.setFocus();
          }
        }, 0);
      },
    [maxDate, maxDateCalendarRef, policyOnYear]
  );

  const handleChangePolicyOnYear = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { minDate } = getValues();
      const isChecked = e?.target?.checked;

      setValue('policyOnYear', isChecked);

      if (isChecked) {
        const newMinDate =
          minDate || new Date(insuranceLimitRes?.startDate || '');
        const newMaxDate = addDays(newMinDate, 364);

        setValue('minDate', newMinDate, { shouldValidate: true });
        setValue('maxDate', newMaxDate, { shouldValidate: true });
        clearErrors('minDate');
        clearErrors('maxDate');
      }
    },
    [insuranceLimitRes]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const { minDate, maxDate, policyOnYear } = value;
      storeNumberInsurePersons(value);
      dispatch({
        type: SportNSActionTypes.SetInsuranceProductStartDate,
        payload: minDate,
      });
      dispatch({
        type: SportNSActionTypes.SetInsuranceProductEndDate,
        payload: maxDate,
      });
      dispatch({
        type: SportNSActionTypes.SetPolicyOnYear,
        payload: policyOnYear,
      });

      if (
        policyOnYear &&
        (name === 'minDate' || name === 'maxDate') &&
        type === 'change'
      ) {
        setValue('policyOnYear', false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, insurePersons, numberInsurePersons]);

  const validatePage = () => {
    const values = getValues();
    let isTimeValid = true;

    if (startDateError.length > 0) {
      isTimeValid = false;
    }

    if (!values.minDate) {
      setError('minDate', {
        type: 'string',
        message: t('SPORT_FORM:errors.startDateOfPolicy') || '',
      });
      isTimeValid = false;
    }

    if (!values.maxDate) {
      setError('maxDate', {
        type: 'string',
        message: t('SPORT_FORM:errors.policyExpirationDate') || '',
      });

      isTimeValid = false;
    }

    const isValid =
      numberInsurePersons !== 0 &&
      !selectPersonsError &&
      isTimeValid &&
      !startDateError.length &&
      !endDateError.length &&
      !errors.minDate &&
      !errors.maxDate;

    dispatch({
      type: SportNSActionTypes.SetInsuranceProductStartDate,
      payload: values.minDate,
    });

    dispatch({
      type: SportNSActionTypes.SetInsuranceProductEndDate,
      payload: values.maxDate,
    });

    return isValid;
  };

  useNextStep(validatePage);
  useSportDraft();

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.sportToStepCount);
  }, []);

  useEffect(() => {
    const { minDate } = getValues();

    if (insuranceLimitRes && minDate?.toString().length > 0) {
      const formattedCurrentValue = format(new Date(minDate), 'yyyy-MM-dd');
      const minDataValue = format(
        new Date(insuranceLimitRes.startDate),
        'yyyy-MM-dd'
      );
      const maxDataValue = format(
        new Date(insuranceLimitRes.endDate),
        'yyyy-MM-dd'
      );

      if (
        formattedCurrentValue === minDataValue ||
        formattedCurrentValue === maxDataValue
      ) {
        return setStartDateError('');
      }

      if (new Date(insuranceLimitRes.startDate) > new Date(minDate)) {
        return clearDateHandler();
      }

      if (new Date(insuranceLimitRes.endDate) < new Date(minDate)) {
        return clearDateHandler();
      }
      return setStartDateError('');
    }
  }, [isLoading, getValues()]);

  useEffect(() => {
    const { maxDate, minDate } = getValues();

    if (startDateError.length > 0) {
      return setEndStartDateError(
        t('SPORT_FORM:errors.unableToIssuePolicy') || ''
      );
    }
    if (startDateError.length === 0) {
      setEndStartDateError('');
    }

    if (
      insuranceLimitRes &&
      maxDate?.toString().length > 0 &&
      minDate?.toString().length > 0 &&
      !startDateError.length
    ) {
      const endDuration = new Date(minDate);

      const minEndDuration = new Date(
        endDuration.setDate(
          endDuration.getDate() + insuranceLimitRes.minDuration - 1
        )
      );

      if (minEndDuration > maxDate) {
        return clearDateHandler();
      }

      return setEndStartDateError('');
    }
  }, [isLoading, getValues(), startDateError]);

  useEffect(() => {
    const values = getValues();
    if (values.minDate) {
      localStorage.setItem('selectedDate', JSON.stringify(values.minDate));
    }
  }, [minDate]);

  useEffect(() => {
    const subscription = watch((values) => {
      const { maxDate, minDate } = values;
      const maxDurationDate = new Date(minDate || insuranceLimitRes?.startDate);
      maxDurationDate.setDate(
        maxDurationDate.getDate() + insuranceLimitRes?.maxDuration - 1
      );

      if (maxDate > maxDurationDate) {
        setError('maxDate', {
          type: 'maxDuration',
          message:
            t('SPORT_FORM:errors.dateGreaterMaxDuration', {
              maxDuration: insuranceLimitRes?.maxDuration,
            }) || '',
        });
      } else if (errors?.maxDate?.type === 'maxDuration') {
        clearErrors('maxDate');
      }

      storeNumberInsurePersons(values);
    });

    return () => subscription.unsubscribe();
  }, [watch, insuranceLimitRes, errors]);

  useEffect(() => {
    if (wantNextStep && !numberInsurePersons) {
      setSelectPersonsError(true);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [wantNextStep]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !!Object.keys(errors).length,
    });
  }, [Object.keys(errors).length]);

  useEffect(() => {
    if (updateFormState) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [updateFormState]);

  useEffect(() => {
    if (selectPersonsError && numberInsurePersons) {
      setSelectPersonsError(false);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [numberInsurePersons]);

  useEffect(() => {
    localStorage.removeItem('successText');
    localStorage.removeItem('startDate');

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: sportNsRoute,
    });
  }, []);

  useEffect(() => {
    if (!!presetData) {
      const categories = presetData.categories;

      dispatch({
        type: SportNSActionTypes.SetChoosedSportKinds,
        payload: getSportOptions(categories),
      });
    }
  }, [presetData]);

  const isDisabledIncrement = useMemo(() => {
    if (insuranceProduct && insuranceProduct.maxObjectsNumber !== null) {
      return numberInsurePersons >= insuranceProduct.maxObjectsNumber;
    }
    return false;
  }, [numberInsurePersons, insuranceProduct]);

  if (isLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  return (
    <Container>
      <FormLabel>{t('SPORT_FORM:headers.numberOfInsured')}</FormLabel>
      <FormSub>{t('SPORT_FORM:subTitles.weCanInsureAnyone')}</FormSub>
      <NumericInputWrapper>
        <NumericInputBox>
          {insuranceProduct?.maxObjectsNumber && (
            <NumericInputLabel>
              {t('SPORT_FORM:labels.maxPersons', {
                maxCount: insuranceProduct.maxObjectsNumber,
              })}
            </NumericInputLabel>
          )}
          <Controller
            control={control}
            name="numberInsurePersons"
            render={({ field }) => (
              <StyledNumericInput
                disabledIncrement={isDisabledIncrement}
                minValue={1}
                readOnly
                {...field}
                testId="sport.numberInsurePersons"
              />
            )}
          />
        </NumericInputBox>
      </NumericInputWrapper>
      <FormLabel marginTop={32}>
        {t('SPORT_FORM:headers.policyPeriod')}
      </FormLabel>
      <StyledText>{t('SPORT_FORM:hints.setPeriod')}</StyledText>
      {!presetData && (
        <StyledCheckboxBox>
          <Controller
            control={control}
            name="policyOnYear"
            render={({ field: { value } }) => (
              <Checkbox
                checked={value}
                onChange={handleChangePolicyOnYear}
                label={t('SPORT_FORM:labels.policyOnYear')}
                name="policyOnYear"
                {...addTestAttribute('sport.policyOnYear.checkbox')}
              />
            )}
          />
        </StyledCheckboxBox>
      )}
      <StyledDatepickerBox>
        <Controller
          control={control}
          name={'minDate'}
          render={({ field: { onBlur, value, onChange }, fieldState }) => (
            <HelperText
              status={
                fieldState.error
                  ? 'error'
                  : Boolean(startDateError.length)
                  ? 'error'
                  : 'default'
              }
              message={errors.minDate?.message || startDateError}
              testId="sport.startDate.error"
            >
              <StyledDatepicker
                error={!!errors.minDate || Boolean(startDateError.length)}
                selected={value}
                showInput
                required
                disabledKeyboardNavigation
                label={t('SPORT_FORM:labels.startDate') || ''}
                onChange={handleOnChangeMinDate(onChange)}
                onBlur={onBlur}
                minDate={convertDateFormatInTimeZone(
                  new Date(insuranceLimitRes.startDate)
                )}
                maxDate={convertDateFormatInTimeZone(
                  maxDate && maxDate < new Date(insuranceLimitRes.endDate)
                    ? maxDate
                    : new Date(insuranceLimitRes.endDate)
                )}
                showDisabledMonthNavigation
                popperContainer={CalendarContainer}
                {...addTestAttribute('sport.startDate')}
              />
            </HelperText>
          )}
        />

        <StyledDateTextMobile isAgent={!!agentLogin}>
          {t('SPORT_FORM:hints.byMoscowTime')}
        </StyledDateTextMobile>

        <Controller
          control={control}
          name={'maxDate'}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={
                fieldState.error
                  ? 'error'
                  : Boolean(endDateError.length)
                  ? 'error'
                  : 'default'
              }
              message={
                <div style={{ maxWidth: 330 }}>
                  {errors.maxDate?.message || endDateError}
                </div>
              }
              testId="sport.endDate.error"
            >
              <StyledDatepicker
                error={!!errors.maxDate || Boolean(endDateError.length)}
                ref={maxDateCalendarRef}
                showInput
                label={t('SPORT_FORM:labels.endDate') || ''}
                onChange={onChange}
                onBlur={onBlur}
                required
                disabledKeyboardNavigation
                minDate={getDays(
                  minDate
                    ? insuranceLimitRes.minDuration - 1
                    : insuranceLimitRes.minDuration,
                  minDate
                )}
                maxDate={getDays(
                  minDate
                    ? insuranceLimitRes.maxDuration - 1
                    : insuranceLimitRes.maxDuration,
                  minDate
                )}
                selected={value}
                showDisabledMonthNavigation
                popperContainer={CalendarContainer}
                {...addTestAttribute('sport.endDate')}
              />
            </HelperText>
          )}
        />
      </StyledDatepickerBox>

      <StyledDateText isAgent={!!agentLogin}>
        {t('SPORT_FORM:hints.byMoscowTime')}
      </StyledDateText>
      {!agentLogin && <UserAgreement />}
    </Container>
  );
};
