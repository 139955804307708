/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { IFLFlatStoreState } from './index';
import { IFLFlatActionTypes } from './index';

export type IFLFlatStoreReducer = (
  state: IFLFlatStoreState,
  action: StoreActions
) => IFLFlatStoreState;

export const formIFLFlatStoreReducer: IFLFlatStoreReducer = (state, action) => {
  switch (action?.type) {
    case IFLFlatActionTypes.SetSelectedIProduct:
      return {
        ...state,
        selectedIProduct: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedRegion:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case IFLFlatActionTypes.SetRegions:
      return {
        ...state,
        regions: action.payload,
      };
    case IFLFlatActionTypes.SetRisks:
      return {
        ...state,
        risks: action.payload,
      };
    case IFLFlatActionTypes.SetRisksPage:
      return {
        ...state,
        risksPage: action.payload,
      };
    case IFLFlatActionTypes.SetInsuranceSubproducts:
      return {
        ...state,
        insuranceSubproducts: action.payload,
      };
    case IFLFlatActionTypes.SetGetPrices:
      return {
        ...state,
        getPrices: action.payload,
      };
    case IFLFlatActionTypes.SetGetPricesFull:
      return {
        ...state,
        getPricesFull: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedDuration:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case IFLFlatActionTypes.SetDadaValue:
      return {
        ...state,
        dadaValue: action.payload,
      };
    case IFLFlatActionTypes.SetInsuranceSum:
      return {
        ...state,
        insuranceSum: action.payload,
      };
    case IFLFlatActionTypes.SetPromoCode:
      return {
        ...state,
        promoCode: action.payload,
      };
    case IFLFlatActionTypes.SetPromoCodeApplyed:
      return {
        ...state,
        promoCodeApplyed: action.payload,
      };
    case IFLFlatActionTypes.SetFieldWithEmptyDependencies:
      return {
        ...state,
        fieldWithEmptyDependencies: action.payload,
      };
    case IFLFlatActionTypes.SetEmptyDependencies:
      return {
        ...state,
        emptyDependencies: action.payload,
      };

    case IFLFlatActionTypes.SetIsObjectDataError:
      return {
        ...state,
        isObjectDataError: action.payload,
      };
    case IFLFlatActionTypes.SetGetSubobjectsRefetchUid:
      return {
        ...state,
        getSubobjectsRefetchUid: action.payload,
      };
    case IFLFlatActionTypes.SetNewSubobjects:
      return {
        ...state,
        newSubobjects: action.payload,
      };
    case IFLFlatActionTypes.SetCurrentSum:
      return {
        ...state,
        currentSum: action.payload,
      };
    case IFLFlatActionTypes.SetCurrentSumMinLimit:
      return {
        ...state,
        currentSumMinLimit: action.payload,
      };
    case IFLFlatActionTypes.SetCurrentSumMaxLimit:
      return {
        ...state,
        currentSumMaxLimit: action.payload,
      };
    case IFLFlatActionTypes.SetCurrentSumErr:
      return {
        ...state,
        currentSumErr: action.payload,
      };
    case IFLFlatActionTypes.SetSelectedId:
      return {
        ...state,
        selectedId: action.payload,
      };
    case IFLFlatActionTypes.SetIsSelectedCustomStreet:
      return {
        ...state,
        isSelectedCustomStreet: action.payload,
      };
    case IFLFlatActionTypes.SetIsSelectedCustomHouse:
      return {
        ...state,
        isSelectedCustomHouse: action.payload,
      };
    case IFLFlatActionTypes.SetIsEmptyStreetSuggestions:
      return {
        ...state,
        isEmptyStreetSuggestions: action.payload,
      };
    case IFLFlatActionTypes.SetIsEmptyHouseSuggestions:
      return {
        ...state,
        isEmptyHouseSuggestions: action.payload,
      };
    case IFLFlatActionTypes.UpdateState:
      return {
        ...state,
        ...action.payload,
      };
    case IFLFlatActionTypes.SetIsSpecialTermsInsurance:
      return {
        ...state,
        isSpecialTermsInsurance: action.payload,
      };
    case IFLFlatActionTypes.SetIsHouseNotFound:
      return {
        ...state,
        isHouseNotFound: action.payload,
      };
    default:
      return state;
  }
};
