/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@pulse-web-ui/spinner';

import {
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest, useValidateProfileAuth } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { useFlatOrderArray } from '@src/pages/ifl-flat-form/hooks';
import { useHouseOrderArray } from '@src/pages/ifl-house-form/hooks';
import {
  AvailableSubscriptionActionTypes,
  CheckUserDataActionTypes,
  IFLFlatActionTypes,
  IFLHouseActionTypes,
  Store,
} from '@src/store';
import {
  GetPrices,
  InsuranceSubProduct,
  InsuranceSubproducts,
} from '@src/types';
import { setDraftLS, subObjectConverter } from '@src/utils';

import { CheckUserDataWrapper, SpinnerWrapper } from './check-user-data.styles';
import { checkPrices, getCheckedSubobjects, getInsuranceSum } from './utils';

const MAX_REQUEST_COUNT = 8;
const REQUEST_IN_PROGRESS = 'IN_PROGRESS';

export const CheckUserData = () => {
  const { t } = useTranslation('CHECK_USER_DATA');
  const navigate = useNavigate();
  const {
    state: {
      stateCheckUserData: {
        productCode,
        risks,
        subobjects,
        promoCode,
        address,
        regionCode,
        addressCode,
        buildingMaterial,
        prices,
        selectedDuration,
      },
      stateFormIFLFlat: {
        insuranceSubproducts: flatInsuranceSubproducts,
        promoCodeApplyed: flatPromoCodeApplyed,
        promoCode: flatPromoCode,
        getPricesFull: flatGetPricesFull,
      },
      stateFormIFLHouse: {
        insuranceSubproducts: houseInsuranceSubproducts,
        promoCodeApplyed: housePromoCodeApplyed,
        promoCode: housePromoCode,
        getPricesFull: houseGetPricesFull,
      },
      stateAuth: { authTokens },
      stateUser: { selectedProduct, profile, isScoringSuccess, agentLogin },
      stateWizard: { currentStep },
      stateFormIFLFlat,
      stateFormIFLHouse,
    },
    dispatch,
  } = useContext(Store);
  const [insuranceSum, setInsuranceSum] = useState<number>();
  const [checkedSubobjects, setCheckedSubobjects] =
    useState<InsuranceSubProduct[]>();
  const [navigateToAvailableSubscription, setNavigateToAvailableSubscription] =
    useState(false);
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  const setFlatOrderArray = useFlatOrderArray();
  const setHouseOrderArray = useHouseOrderArray();

  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, []);

  const { isLoading, error, res, refetch } = useRequest<InsuranceSubproducts>(
    'getCheckDataSubobjects',
    'post',
    '/v1/references/get-subobjects',
    {
      productCode,
      region: regionCode,
      risks: risks?.map(({ code }) => code),
      buildingMaterial,
    },
    [
      risks,
      productCode,
      regionCode,
      buildingMaterial,
      authTokens?.authorization?.accessToken,
    ],
    !isScoringSuccess,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: isPricesLoading,
    error: pricesError,
    res: pricesRes,
    refetch: refetchPrices,
  } = useRequest<GetPrices>(
    'getCheckDataPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      risks: risks?.map(({ code }) => code),
      productCode,
      insuranceSum,
      personProperties: {
        subObjects: checkedSubobjects && subObjectConverter(checkedSubobjects),
        address: {
          regionCode: regionCode,
          address: address,
          addressCode: addressCode,
        },
      },
      returnMinDuration: false,
      buildingMaterial,
      promoCode,
    },
    [
      risks,
      productCode,
      insuranceSum,
      checkedSubobjects,
      regionCode,
      address,
      addressCode,
      buildingMaterial,
      promoCode,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  let timeout: NodeJS.Timeout;
  let requestCount = 1;

  const refetchSubobjects = (status: string | undefined) => {
    if (status === REQUEST_IN_PROGRESS && requestCount < MAX_REQUEST_COUNT) {
      requestCount = requestCount + 1;

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        refetch().then((res) => {
          refetchSubobjects(res?.data?.data.applicationStatus);
        });
      }, 1000);
    } else {
      clearTimeout(timeout);

      if (res?.subObjects && subobjects) {
        const { hasChanged, checkedSubobjects } = getCheckedSubobjects(
          subobjects,
          res.subObjects
        );
        const changedInsuranceSum = getInsuranceSum(checkedSubobjects);

        switch (selectedProduct) {
          case Product.HOUSE:
            const houseDraftData = {
              dataObject: {
                ...stateFormIFLHouse,
                insuranceSubproducts: {
                  ...houseInsuranceSubproducts,
                  ...res,
                  subObjects: checkedSubobjects,
                },
                promoCode: housePromoCodeApplyed && housePromoCode,
                currentSum: changedInsuranceSum,
                insuranceSum: changedInsuranceSum,
              },
              profile,
              selectedProduct,
              currentStep: currentStep || 0,
            };

            dispatch({
              type: IFLHouseActionTypes.SetInsuranceSubproducts,
              payload: {
                ...houseInsuranceSubproducts,
                ...res,
                subObjects: checkedSubobjects,
              },
            });

            dispatch({
              type: IFLHouseActionTypes.SetInsuranceSum,
              payload: changedInsuranceSum,
            });

            dispatch({
              type: IFLHouseActionTypes.SetIsSpecialTermsInsurance,
              payload: true,
            });

            dispatch({
              type: IFLHouseActionTypes.SetCurrentSumMinLimit,
              payload: res?.totalMinLimit || '0',
            });

            dispatch({
              type: IFLHouseActionTypes.SetCurrentSumMaxLimit,
              payload: res?.totalMaxLimit || '0',
            });

            if (!agentLogin) {
              setDraftLS(houseDraftData);
            }
            break;
          case Product.APARTMENT:
            const flatDraftData = {
              dataObject: {
                ...stateFormIFLFlat,
                insuranceSubproducts: {
                  ...flatInsuranceSubproducts,
                  ...res,
                  subObjects: checkedSubobjects,
                },
                promoCode: flatPromoCodeApplyed && flatPromoCode,
                currentSum: changedInsuranceSum,
                insuranceSum: changedInsuranceSum,
              },
              profile,
              selectedProduct,
              currentStep: currentStep || 0,
            };

            dispatch({
              type: IFLFlatActionTypes.SetInsuranceSubproducts,
              payload: {
                ...flatInsuranceSubproducts,
                ...res,
                subObjects: checkedSubobjects,
              },
            });

            dispatch({
              type: IFLFlatActionTypes.SetInsuranceSum,
              payload: changedInsuranceSum,
            });

            dispatch({
              type: IFLFlatActionTypes.SetIsSpecialTermsInsurance,
              payload: true,
            });

            dispatch({
              type: IFLFlatActionTypes.SetCurrentSumMinLimit,
              payload: res?.totalMinLimit || '0',
            });

            dispatch({
              type: IFLFlatActionTypes.SetCurrentSumMaxLimit,
              payload: res?.totalMaxLimit || '0',
            });

            if (!agentLogin) {
              setDraftLS(flatDraftData);
            }
            break;
        }

        setNavigateToAvailableSubscription(hasChanged);
        setCheckedSubobjects(checkedSubobjects);
        setInsuranceSum(changedInsuranceSum);
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      refetchSubobjects(res?.applicationStatus);
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (checkedSubobjects && insuranceSum) {
      refetchPrices();
    }
  }, [checkedSubobjects, insuranceSum]);

  useEffect(() => {
    switch (selectedProduct) {
      case Product.HOUSE:
        setHouseOrderArray(pricesRes);
        break;
      case Product.APARTMENT:
        setFlatOrderArray(pricesRes);
        break;
    }
  }, [pricesRes, flatGetPricesFull, houseGetPricesFull]);

  useEffect(() => {
    if (!isPricesLoading && pricesRes) {
      const oldPrice = prices?.find(
        (price) => price.duration === selectedDuration
      );
      const newPrice = pricesRes?.prices?.find(
        (price) => price.duration === selectedDuration
      );

      if (oldPrice && newPrice) {
        const hasChanged = !checkPrices(oldPrice, newPrice, !!promoCode);
        setNavigateToAvailableSubscription(hasChanged);
        setReadyToNavigate(true);
      }
      switch (selectedProduct) {
        case Product.HOUSE:
          dispatch({
            type: IFLHouseActionTypes.SetGetPricesFull,
            payload: pricesRes,
          });
          break;
        case Product.APARTMENT:
          dispatch({
            type: IFLFlatActionTypes.SetGetPricesFull,
            payload: pricesRes,
          });
          break;
      }
    }
  }, [isPricesLoading, pricesRes]);

  useEffect(() => {
    if (readyToNavigate) {
      if (navigateToAvailableSubscription) {
        const price = pricesRes?.prices?.find(
          (price) => price.duration === selectedDuration
        );
        dispatch({
          type: AvailableSubscriptionActionTypes.SetRisks,
          payload: risks,
        });
        dispatch({
          type: AvailableSubscriptionActionTypes.SetSubobjects,
          payload: checkedSubobjects,
        });
        dispatch({
          type: AvailableSubscriptionActionTypes.SetInsuranceSum,
          payload: insuranceSum,
        });
        dispatch({
          type: AvailableSubscriptionActionTypes.SetInsuranceSumPerMonth,
          payload: Number(price?.premiumAndDelta),
        });
        dispatch({
          type: AvailableSubscriptionActionTypes.SetInsurancePromoSumPerMonth,
          payload: Number(price?.premiumAndDeltaPromo),
        });
        dispatch({
          type: AvailableSubscriptionActionTypes.SetNextRoute,
          payload: '/order-detail',
        });
        dispatch({
          type: AvailableSubscriptionActionTypes.SetPrevRoute,
          payload: selectedProduct === Product.HOUSE ? '/house' : '/apartment',
        });

        navigate('/available-subscription');
      } else {
        sendAnalyticEvent(analyticEvents.iflStep7Confirm);

        if (selectedProduct === Product.HOUSE) {
          sendAnalyticEvent(analyticEvents.iflHouseStep7Confirm);
        } else {
          sendAnalyticEvent(analyticEvents.iflFlatStep7Confirm);
        }

        navigate('/order-detail');
      }
    }
  }, [navigateToAvailableSubscription, readyToNavigate]);

  useEffect(
    () => () => {
      dispatch({
        type: CheckUserDataActionTypes.ResetState,
        payload: {},
      });
    },
    []
  );

  if (profileError) {
    const profileErrorStatus = profileError?.response?.status;

    if (profileErrorStatus !== 401 && profileErrorStatus !== 403) {
      return (
        <GlobalErrorInfo
          pending={profileIsLoading}
          retrayHandler={profileRefetch}
        />
      );
    }
  }

  if (error && error?.response?.status === 400) {
    if (error?.response?.data?.code === 'TECHNICAL_ERROR') {
      return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
    }
    if (error?.response?.data?.code === 'USER_DATA_ERROR') {
      navigate('/score-error');
    }
  }

  if (pricesError && pricesError?.response?.status === 400) {
    if (pricesError?.response?.data?.code === 'TECHNICAL_ERROR') {
      return (
        <GlobalErrorInfo
          pending={isPricesLoading}
          retrayHandler={refetchPrices}
        />
      );
    }
    if (pricesError?.response?.data?.code === 'OBJECT_DATA_ERROR') {
      if (selectedProduct === Product.HOUSE) {
        dispatch({
          type: IFLHouseActionTypes.SetIsObjectDataError,
          payload: true,
        });
      } else {
        dispatch({
          type: IFLFlatActionTypes.SetIsObjectDataError,
          payload: true,
        });
      }
      navigate('/score-error');
    }
  }

  return (
    <BaseLayout>
      <Container>
        <CheckUserDataWrapper>
          <SpinnerWrapper>
            <Spinner size={40} />
          </SpinnerWrapper>
          <HeaderAdaptive1WithSubTitle align="center">
            {t('headers.checkYourData')}
          </HeaderAdaptive1WithSubTitle>
          <HeaderAdaptive5>{t('hints.usuallyItTakes')}</HeaderAdaptive5>
        </CheckUserDataWrapper>
      </Container>
    </BaseLayout>
  );
};
