import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Skeleton } from '@src/components';
import { insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNavigateToVip, useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { DraftActionTypes, NSActionTypes, Store } from '@src/store';
import type { NSStoreState } from '@src/store';
import type { InsuranceProductType } from '@src/types';
import { cleartDraft, getDraft } from '@src/utils';

import { NSForm } from './ns-form';

export const NSPage: FC = () => {
  const {
    state: {
      stateDraft: { checkDraft },
      stateUser: { selectedProduct, profile },
    },
    dispatch,
  } = useContext(Store);
  const { shouldNavigateToVip } = useNavigateToVip();

  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest(
    'formNSGetInsuranceProduct',
    'post',
    '/v1/references/get-insurance-product',
    {
      productCode: insuranceProductsCode.accident,
    }
  );

  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      const data: InsuranceProductType = { ...resProduct };

      dispatch({
        type: NSActionTypes.SetInsuranceProduct,
        payload: data,
      });
    }
  }, [productIsLoading, resProduct]);

  const [isWait, setIsWait] = useState(true);

  useEffect(() => {
    if (productIsLoading) {
      return;
    }

    if (
      checkDraft &&
      selectedProduct &&
      !productIsLoading &&
      resProduct &&
      profile
    ) {
      dispatch({
        type: DraftActionTypes.SetCheckDraft,
        payload: false,
      });
      const { resData } = getDraft();

      if (resData) {
        if (resData.common.productType === resProduct.code) {
          const state = resData.state as unknown as NSStoreState;

          dispatch({
            type: NSActionTypes.SetInsuranceProduct,
            payload: state?.insuranceProduct,
          });
          dispatch({
            type: NSActionTypes.SetAgeRatios,
            payload: state?.ageRatios || [],
          });
          dispatch({
            type: NSActionTypes.SetNumberInsurePersons,
            payload: state?.numberInsurePersons || {
              numberChildren: 0,
              numberAdults: 0,
              numberElderly: 0,
            },
          });
          dispatch({
            type: NSActionTypes.SetRisks,
            payload: state?.risks,
          });
          dispatch({
            type: NSActionTypes.SetSelectedMainRisks,
            payload: state?.selectedMainRisks,
          });
          dispatch({
            type: NSActionTypes.SetSelectedAdditionalRisks,
            payload: state?.selectedAdditionalRisks,
          });
          dispatch({
            type: NSActionTypes.SetInsuranceSum,
            payload: state?.insuranceSum || 20000,
          });
          dispatch({
            type: NSActionTypes.SetSelectedDate,
            payload:
              state?.selectedDate && state?.selectedDate !== null
                ? new Date(state?.selectedDate as unknown as string)
                : null,
          });
          dispatch({
            type: NSActionTypes.SetSelectedDuration,
            payload: state?.selectedDuration,
          });
          dispatch({
            type: NSActionTypes.SetGetPrices,
            payload: state?.getPrices,
          });
          dispatch({
            type: NSActionTypes.SetSubscriptionDateLimit,
            payload: state?.subscriptionDateLimit || null,
          });
          dispatch({
            type: NSActionTypes.SetPromoCode,
            payload: state?.promoCode,
          });
        } else {
          cleartDraft();
        }
      }
      setIsWait(false);
    } else if (!checkDraft) {
      setIsWait(false);
    }
  }, [checkDraft, selectedProduct, productIsLoading, resProduct, profile]);

  if (!profile || productIsLoading || isWait || shouldNavigateToVip)
    return <Skeleton />;

  if (productError) {
    return (
      <GlobalErrorInfo
        pending={productIsLoading}
        retrayHandler={productRefetch}
      />
    );
  }

  return (
    <BaseLayout>
      <NSForm />
    </BaseLayout>
  );
};
