import type { FC } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { SelectorCard } from '@pulse-web-ui/selector-card';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  IflSumPerMonthContainer,
  LinkContainer,
  RiskWrapper,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { FeatureFlags, analyticEvents } from '@src/constants';
import { GlobalErrorInfo, IflSumPerMonth } from '@src/features';
import {
  useFeatureFlags,
  useHandlePressKey,
  useNextStep,
  useRequest,
} from '@src/hooks';
import {
  createSublimitsFromPresetData,
  getTemporaryFranchise,
  sublimitRisksConverter,
} from '@src/pages/pets-form/utils';
import { Coverage, PetsActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import { currencyRuLocaleWithoutFraction, evenRisk } from '@src/utils';

import { CostOnRiskTitle } from './components/cost-on-risk-title';
import { usePetsDraft } from './hooks';

export const PetsAdditionalRisks: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormPets: {
        risks,
        selectedIProduct,
        selectedPetData,
        sublimits,
        currentSubLimits,
        presetData,
        promoCodeApplyed,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  const [newSubLimits, setNewSubLimits] = useState<Coverage[] | undefined>();
  const {
    res: [isShowCostOnRiskScreens],
  } = useFeatureFlags([FeatureFlags.ShowCostOnRiskScreens]);

  const activeRisks = useMemo(
    () =>
      risks
        ?.filter((riskItem) => {
          if (riskItem.active) {
            return riskItem;
          }
        })
        .map((filteredRiskItem) => filteredRiskItem.code),
    [risks]
  );

  const { isLoading, error, res, refetch, isRefetching } = useRequest(
    'additionalRisksGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      insuranceSum: 0,
      productCode: selectedIProduct?.code,
      returnMinDuration: true,
      risks: activeRisks,
      pets: {
        kind: selectedPetData?.kind,
        age: selectedPetData?.age,
        coverages: newSubLimits,
      },
      promoCode: promoCodeApplyed ? promoCodeApplyed : undefined,
    },
    [
      selectedIProduct?.code,
      activeRisks,
      selectedPetData?.kind,
      selectedPetData?.age,
      newSubLimits,
      promoCodeApplyed,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleKeyPressEnter = () => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !risks?.some(evenRisk) || false,
    });

    return risks?.some(evenRisk) || false;
  }, [risks]);

  useNextStep(validatePage);
  usePetsDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (isShowCostOnRiskScreens && currentSubLimits?.risks) {
      const changedLimits =
        sublimits?.risks.filter((risk) => activeRisks?.includes(risk.code)) ||
        [];

      const currentSelectedRisks =
        currentSubLimits.risks.filter(
          (risk) =>
            activeRisks?.includes(risk.code) &&
            !changedLimits.find((item) => item.code === risk.code)
        ) || [];

      setNewSubLimits(
        sublimitRisksConverter([...currentSelectedRisks, ...changedLimits])
      );
    }
  }, [currentSubLimits, sublimits, isShowCostOnRiskScreens]);

  useEffect(() => {
    if (isShowCostOnRiskScreens && risks && newSubLimits) {
      refetch();
    }
  }, [risks, newSubLimits, isShowCostOnRiskScreens]);

  const handleRiskClick = (code: string) => {
    if (!!presetData) {
      sendAnalyticEvent(analyticEvents.petChangeAddRiskPreset);
    }
    setStepUpdated();

    dispatch({
      type: PetsActionTypes.SetSublimits,
      payload: undefined,
    });

    const newRisks = risks?.map((item) => {
      if (item.code === code) {
        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    dispatch({
      type: PetsActionTypes.SetRisks,
      payload: newRisks,
    });

    if (isShowCostOnRiskScreens) {
      const currentSelectedRisks =
        currentSubLimits?.risks.filter((risk) =>
          newRisks?.find((item) => item.active && item.code === risk.code)
        ) || [];

      const payload = {
        ...currentSubLimits,
        risks: currentSelectedRisks,
      };

      dispatch({
        type: PetsActionTypes.SetSublimits,
        payload: !!presetData
          ? createSublimitsFromPresetData(presetData, payload)
          : payload,
      });

      setNewSubLimits(sublimitRisksConverter(currentSelectedRisks));
    }
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: PetsActionTypes.SetRisksPage,
      payload: 'secondary',
    });
    navigate('/pets-risk-descriptions');
  };

  if (
    error &&
    error?.response?.status !== 401 &&
    error?.response?.data?.code !== 'VALIDATION_ERROR' &&
    error?.response?.data?.code !== 'BUSINESS_ERROR' &&
    error?.response?.data?.code !== 'TECHNICAL_ERROR'
  ) {
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
  }

  return (
    <Container>
      <FormLabel>{t('COMMON:headers.selectAdditionalRisks')}</FormLabel>
      <FormSub>{t('PETS_FORM_DATA:hints.eventsOccurLessFrequently')}</FormSub>
      <AdaptiveListWrapper>
        {(!risks || risks?.length === 0) && t('COMMON:errors.noData')}
        {risks?.map((item) =>
          item.binding ? null : (
            <RiskWrapper key={item.code}>
              <SelectorCard
                key={`${item.code}_${item.name}`}
                id={item.code}
                name={item.code}
                label={item.name}
                mobileFullWidth
                description={item.description}
                checked={item.active}
                readOnly={!item.switchability}
                onClick={() => handleRiskClick(item.code)}
                caption={
                  item?.temporaryFranchise
                    ? getTemporaryFranchise(item.temporaryFranchise)
                    : null
                }
              />
            </RiskWrapper>
          )
        )}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutAdditionalRisks') || ''}
        />
      </LinkContainer>
      {isShowCostOnRiskScreens && (
        <SumWrapper>
          <IflSumPerMonthContainer>
            {newSubLimits && (
              <IflSumPerMonth
                isLoading={isLoading || isRefetching || !!error}
                sumPerMonth={Math.ceil(
                  Number(res?.prices[0].premiumAndDelta) / 3
                )}
                sumPromoPerMonth={
                  res?.prices[0]?.premiumAndDeltaPromo &&
                  Math.ceil(Number(res?.prices[0].premiumAndDeltaPromo) / 3)
                }
                sumPerMonthDesc={t('COMMON:labels.perMonth')}
                title={
                  <CostOnRiskTitle activeRiskCount={activeRisks?.length || 0} />
                }
                description={t('PETS_FORM_DATA:labels.writeInThreeMonths', {
                  cost: `${currencyRuLocaleWithoutFraction(
                    Math.ceil(
                      res?.prices[0]?.premiumAndDeltaPromo ||
                        res?.prices[0].premiumAndDelta
                    )
                  )} ₽`,
                })}
              />
            )}
          </IflSumPerMonthContainer>
        </SumWrapper>
      )}
    </Container>
  );
};
