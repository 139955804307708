import { memo, useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { ErrorAlertIcon } from '@pulse-web-ui/colored-icons';

import { HeaderAdaptive1 } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { PAYMENT_ROUTE, analyticEvents } from '@src/constants';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { getProductForAnalytic, useScrollToIframeOnMount } from '@src/utils';

import {
  GlobalErrorContent,
  GlobalErrorFormSub,
  GlobalErrorWrapper,
} from './global-error-info.styles';

const getCurrentStep = (pathname: string, wizardStep: number) => {
  if (pathname === '/order-detail') return wizardStep + 1;
  else if (pathname === '/order-checkout') return wizardStep + 2;
  else if (pathname === PAYMENT_ROUTE) return 'pay-error';
  else if (pathname === '/personal-info') return 'personal-info-error';
  else return wizardStep;
};

type Props = {
  pending?: boolean;
  retrayHandler?: () => void;
};

export const GlobalErrorInfo: FC<Props> = memo(({ pending, retrayHandler }) => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { selectedProduct },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const { pathname } = useLocation();

  const handleRetry = () => {
    if (retrayHandler) {
      retrayHandler();
    }
  };

  useScrollToIframeOnMount(selectedProduct);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: true,
    });

    dispatch({
      type: WizardActionTypes.SetGlobalError,
      payload: true,
    });

    const timeout = setTimeout(() => {
      sendAnalyticEvent(analyticEvents.somethingWentWrong, {
        product: getProductForAnalytic(selectedProduct),
        screen: getCurrentStep(pathname, currentStep),
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <GlobalErrorWrapper>
      <ErrorAlertIcon />
      <GlobalErrorContent>
        <HeaderAdaptive1 align="center">
          {t('AUTH:headers.whenLoadingData')}
          <br />
          {t('AUTH:headers.somethingWentWrong')}
        </HeaderAdaptive1>
        <GlobalErrorFormSub>
          {t('AUTH:hints.checkConnectionQuality')}
        </GlobalErrorFormSub>
      </GlobalErrorContent>
      {retrayHandler ? (
        <Button
          disabled={pending}
          variant={pending ? 'secondary' : 'primary'}
          label={t('COMMON:buttons.tryAgain') || ''}
          onClick={handleRetry}
        />
      ) : (
        <></>
      )}
    </GlobalErrorWrapper>
  );
});
