import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Container } from '@src/components';
import type { OrderItemType, OrderItemsBlockType } from '@src/types';
import { addTestAttribute } from '@src/utils';

import {
  OrderBlockItemHead,
  OrderBlockItemTitle,
  OrderBlockItemTitleButton,
  OrderBlockItemWrapper,
} from './components.styles';
import { OrderItem } from './order-item';

export const OrderBlockItem: FC<OrderItemsBlockType> = ({
  title,
  onClick,
  value,
  testId = 'orderBlockItem',
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <OrderBlockItemWrapper>
        <OrderBlockItemTitle>
          <OrderBlockItemHead {...addTestAttribute(`${testId}.title`)}>
            {title}
          </OrderBlockItemHead>
          {onClick ? (
            <OrderBlockItemTitleButton
              onClick={onClick}
              {...addTestAttribute(`${testId}.button`)}
            >
              {t('COMMON:labels.change')}
            </OrderBlockItemTitleButton>
          ) : null}
        </OrderBlockItemTitle>
        {value
          ?.filter((subItem) => subItem.value)
          ?.map((filteredSubItem: OrderItemType) => (
            <OrderItem key={uuid()} {...filteredSubItem} testId={testId} />
          ))}
      </OrderBlockItemWrapper>
    </Container>
  );
};
