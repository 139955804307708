/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ErrorOption,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Container, FormLabel, FormSub, Skeleton } from '@src/components';
import { insurancePersonDefaultData } from '@src/constants';
import { MitePolicyChoose } from '@src/constants/mite-choose';
import { GlobalErrorInfo } from '@src/features';
import { InsurancePersonForm } from '@src/features/insurance-person-form';
import {
  useBlockNextStep,
  useRequest,
  useScrollToErrorElement,
  useValidateProfileAuth,
} from '@src/hooks';
import { updateOrderProfileData } from '@src/pages/ns-form/utils/update-order-profile-data';
import { personsFormSchema } from '@src/schemas';
import {
  AuthActionTypes,
  MiteActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { InsurancePersonsForm, InsurePerson } from '@src/types';
import { getFormattedDate, setPaymentPageTexts } from '@src/utils';

import { useNsDetailsInsured } from '../ns-form/hooks';
import { useMiteOrderDataFormatting, userMiteOrderData } from './hooks';
import { getInsuranceObjects } from './utils';

export const MiteInsured: FC = (): ReactElement => {
  const [changedProfileData, setChangedProfileData] = useState<null | Record<
    string,
    string
  >>(null);
  const {
    state: {
      stateMite: {
        insuredPersons,
        numberInsuredPersons,
        insuranceProductPolicy,
        choosedPolicy,
        startDate,
      },
      stateWizard: { wantNextStep },
      stateUser: { profile },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const selectedPolicy = useMemo(
    () =>
      insuranceProductPolicy?.antimitePrograms?.find(
        (product) => product.insuranceProgram === choosedPolicy
      ),
    []
  );

  const selectedStartDate = selectedPolicy?.startDate
    ? new Date(selectedPolicy?.startDate)
    : null;

  const { t } = useTranslation();
  const [submitPersonsData, setSubmitPersonsData] = useState<
    undefined | InsurePerson[]
  >();
  const [indexMyPersonForm, setIndexMyPersonForm] = useState(-1);
  const { orderData } = userMiteOrderData();
  const { orderArray } = useMiteOrderDataFormatting();

  const navigate = useNavigate();
  const { storeWantNextStep, storeMiteInsurePersons, profileDataForForm } =
    useNsDetailsInsured();

  const { profileError, profileIsLoading, profileRefetch } =
    useValidateProfileAuth();

  const defaultValues = useMemo(
    () => ({
      persons: insuredPersons.map((person) => ({
        id: uuidv4(),
        ...person,
      })),
    }),
    [insuredPersons]
  );

  const methods = useForm<InsurancePersonsForm>({
    mode: 'all',
    resolver: yupResolver(personsFormSchema),
    defaultValues,
  });

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, [profile?.profile.lastName]);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    setError,
    formState: { errors, isSubmitted },
  } = methods;

  useScrollToErrorElement(errors);
  const [_, setPersonsErrors] = useState(errors.persons);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'persons',
    keyName: 'key',
  });

  useEffect(() => {
    if (isSubmitted) {
      setPersonsErrors(errors.persons);
    }
  }, [isSubmitted, errors.persons]);

  const handleSelectionMe = (personsIndex: number) => {
    const formData = getValues();
    const newData = formData.persons.map((item, idx) => {
      if (idx === personsIndex) {
        if (item.isMe) {
          return {
            ...item,
            ...profileDataForForm,
            isMe: true,
            isDisabledForm: profile?.hasSubscriptions,
          };
        }
        return { ...insurancePersonDefaultData, id: item.id };
      }

      if (item.isMe) {
        return { ...insurancePersonDefaultData, id: item.id };
      }

      return item;
    });

    reset({ persons: newData });

    setPersonsErrors((prevState) => {
      const newErrors = prevState?.map((err, index) => {
        if (index === personsIndex) {
          return {};
        }
        return err;
      });

      setError('persons', newErrors as ErrorOption);
      return newErrors;
    });

    setIndexMyPersonForm((prevIndex) =>
      prevIndex === personsIndex ? -1 : personsIndex
    );
  };

  const {
    error: updateProfileError,
    isLoading: updateProfileIsLoading,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileMiteRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        ...changedProfileData,
      },
    },
    [changedProfileData],
    true,
    authTokens?.authorization?.accessToken
  );

  const setNewPrimaryRecordId = () => {
    const isMePerson = getValues().persons.find(({ isMe }) => isMe);

    if (isMePerson) {
      const isPrimaryRecordIdChaged =
        isMePerson.primaryRecordId !== profileDataForForm.primaryRecordId;

      if (isPrimaryRecordIdChaged) {
        const newInsuredPersons = insuredPersons.map((person) => {
          if (person.isMe) {
            return {
              ...person,
              primaryRecordId: profileDataForForm.primaryRecordId,
            };
          }

          return person;
        });

        storeMiteInsurePersons(newInsuredPersons);
        reset({ persons: newInsuredPersons });
      }
    }
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    setIndexMyPersonForm(insuredPersons.findIndex(({ isMe }) => isMe));

    setNewPrimaryRecordId();
  }, []);

  useEffect(() => {
    const subscription = watch((value) =>
      storeMiteInsurePersons(value.persons as InsurePerson[])
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (wantNextStep) {
      storeWantNextStep(false);

      handleSubmit((data) => {
        let isEqual = true;
        const changedUserData = data.persons.find((item) => item.isMe);
        if (changedUserData) {
          for (const key in profileDataForForm) {
            if (
              profileDataForForm[key as keyof typeof profileDataForForm] !==
              changedUserData[key as keyof typeof profileDataForForm]
            ) {
              isEqual = false;
              break;
            }
          }
        }

        setPaymentPageTexts(
          t('COMMON:errors.ifSomethingHappens'),
          selectedStartDate
        );
        if (isEqual) {
          if (profile?.profile) {
            dispatch({
              type: OrderActionTypes.SetOrder,
              payload: updateOrderProfileData(orderArray, profile.profile),
            });
          }
          dispatch({
            type: OrderActionTypes.SetOrderRequestData,
            payload: orderData,
          });
          navigate('/order-detail');
        } else if (changedUserData) {
          const { firstName, lastName, middleName, birthDate } =
            changedUserData;

          const updateUserProfileData: Record<string, string> = {};
          if (!profile?.lockedFields?.firstName && firstName) {
            updateUserProfileData.firstName = firstName;
          }
          if (!profile?.lockedFields?.middleName) {
            updateUserProfileData.middleName = middleName || '';
          }
          if (!profile?.lockedFields?.lastName && lastName) {
            updateUserProfileData.lastName = lastName;
          }
          if (!profile?.lockedFields?.birthDate && birthDate) {
            updateUserProfileData.birthDate = getFormattedDate(
              new Date(birthDate)
            );
          }

          setChangedProfileData(updateUserProfileData);
          setSubmitPersonsData(data.persons);
        }
      })();
    }
  }, [wantNextStep]);

  useEffect(() => {
    if (
      !updateProfileIsLoading &&
      updateProfileRes &&
      submitPersonsData &&
      changedProfileData
    ) {
      const newData = [...submitPersonsData];

      const myIndex = newData.findIndex((item) => item.isMe);
      newData[myIndex].primaryRecordId =
        updateProfileRes.profile.primaryRecordId;

      orderData.productProperty.insuranceObjects = getInsuranceObjects(newData);

      dispatch({
        type: OrderActionTypes.SetOrderRequestData,
        payload: orderData,
      });

      navigate('/order-detail');
    }
  }, [
    updateProfileIsLoading,
    updateProfileRes,
    submitPersonsData,
    changedProfileData,
  ]);

  useEffect(() => {
    if (changedProfileData) {
      updateProfileRefetch();
    }
  }, [changedProfileData]);

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      dispatch({
        type: OrderActionTypes.SetOrder,
        payload: updateOrderProfileData(orderArray, updateProfileRes.profile),
      });
    }
  }, [updateProfileRes]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: t('MITE_FORM:labels.movement') as string,
    });
  }, []);

  useEffect(() => {
    if (startDate) {
      localStorage.setItem('selectedDate', JSON.stringify(startDate));
    }
  }, [startDate]);

  useBlockNextStep(
    profileIsLoading || updateProfileIsLoading,
    profileError || updateProfileError
  );

  if (updateProfileIsLoading || profileIsLoading || !profile?.profile.lastName)
    return <Skeleton />;

  if (updateProfileError) {
    const e = updateProfileError?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }

    return (
      <GlobalErrorInfo
        pending={updateProfileIsLoading}
        retrayHandler={updateProfileRefetch}
      />
    );
  }

  if (profileError) {
    const e = profileError?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }

    return (
      <GlobalErrorInfo
        pending={profileIsLoading}
        retrayHandler={profileRefetch}
      />
    );
  }

  const handleAddField = () => {
    const { persons } = getValues();
    const newPerson = { ...insurancePersonDefaultData, id: uuidv4() };
    dispatch({
      type: MiteActionTypes.SetInsuredPersons,
      payload: [...persons, newPerson],
    });

    dispatch({
      type: MiteActionTypes.SetNumberInsuredPersons,
      payload: numberInsuredPersons + 1,
    });

    append(newPerson);
  };

  const handleDeleteField = (personsIndex: number) => {
    const { persons } = getValues();

    const filteredPersonArray = persons.filter(
      (_, idx) => idx !== personsIndex
    );

    dispatch({
      type: MiteActionTypes.SetInsuredPersons,
      payload: filteredPersonArray,
    });

    dispatch({
      type: MiteActionTypes.SetNumberInsuredPersons,
      payload: numberInsuredPersons - 1,
    });

    remove(personsIndex);

    const isMeIndex = filteredPersonArray.findIndex((person) => person.isMe);
    setIndexMyPersonForm(isMeIndex);
  };

  return (
    <FormProvider {...methods}>
      <Container>
        <FormLabel>
          {choosedPolicy === MitePolicyChoose.RELATIVES
            ? t('MITE_FORM:headers.enterNoMoreThanFourInsured')
            : t('MITE_FORM:headers.enterInsured')}
        </FormLabel>
        <FormSub margin="8px 0 32px">
          {choosedPolicy === MitePolicyChoose.RELATIVES
            ? t('MITE_FORM:headers.enterInsuredSubLabel')
            : t('MITE_FORM:headers.enterOneInsuredSubLabel')}
        </FormSub>
        {fields.map((field, fieldIndex) => (
          <InsurancePersonForm
            key={field.id}
            isLastPerson={fieldIndex + 1 === fields.length}
            handleAddField={handleAddField}
            handleDeleteField={handleDeleteField}
            fields={fields}
            pressEnterNextStep={false}
            personsIndex={fieldIndex}
            selectedIndex={indexMyPersonForm}
            effectiveSince={new Date()}
            lockedFields={profile?.lockedFields}
            handleSelectionMe={handleSelectionMe}
            ageMin={field.isMe && !field.birthDate ? 18 : undefined}
            showAddButton={
              choosedPolicy === MitePolicyChoose.RELATIVES && fields.length <= 3
            }
            showDeleteButton={
              choosedPolicy === MitePolicyChoose.RELATIVES && fields.length > 2
            }
          />
        ))}
      </Container>
    </FormProvider>
  );
};
