/* eslint-disable indent */
import {
  FC,
  ReactElement,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Pagination, Swiper } from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import { Container, Skeleton } from '@src/components';
import { AnalyticsType, sendAnalyticEvent } from '@src/components/web-analytic';
import {
  analyticEvents,
  insurancePersonDefaultData,
  insuranceProductsCode,
} from '@src/constants';
import { MitePolicyChoose } from '@src/constants/mite-choose';
import { useHandlePressKey, useIsDesktop, useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { AuthPageTitle } from '@src/pages/authorization/authorization-pages.styles';
import { MiteActionTypes, Store, WizardActionTypes } from '@src/store';
import { KeyCode } from '@src/types';
import { convertDateFormatInTimeZone } from '@src/utils';

import { GlobalErrorInfo } from '../global-error-info';
import { useMiteChoose } from './hooks/useMiteChoose';
import {
  StyledBorder,
  StyledButton,
  StyledButtonBox,
  StyledCardBox,
  StyledCardContainer,
  StyledCardItem,
  StyledCardListKey,
  StyledCardListValue,
  StyledCardSubTitle,
  StyledCardTitle,
  StyledPriceBox,
  StyledSwiperPriceValue,
  StyledSwiperReact,
  StyledTarifCardList,
} from './mite.styles';

interface CardType {
  key: string | JSX.Element;
  value: JSX.Element | string;
  isLast?: boolean;
}

Swiper.use([Pagination]);

export const MitePolicyCards: FC = memo((): ReactElement => {
  const {
    state: {
      stateMite: {
        insuredPersons,
        numberInsuredPersons,
        insuranceProductPolicy,
        choosedPolicy,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const [isPolicyClicked, setIsPolicyClicked] = useState(false);
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const MITE_CHOOSE = useMiteChoose();

  const { isLoading, error, res, refetch } = useRequest(
    'mitePolicyChoose',
    'get',
    `/v4/references/insurance-programs/${insuranceProductsCode.mite}`,
    {},
    [],
    false,
    authTokens?.authorization?.accessToken
  );

  const storeInsuranceProduct = useCallback(
    (data) => {
      dispatch({
        type: MiteActionTypes.SetInsuranceProductPolicy,
        payload: data || undefined,
      });
    },
    [res]
  );

  const handleKeyPressEnter = () => {
    dispatch({
      type: MiteActionTypes.SetChoosedPolicy,
      payload: choosedPolicy ? choosedPolicy : MitePolicyChoose.PERSON_PLUS,
    });
    setIsPolicyClicked(true);
    sendAnalyticEvent(
      analyticEvents.miteSubmitApp,
      {},
      AnalyticsType.myTracker
    );
  };

  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const storeNumberInsurePersons = useCallback(
    (numberInsuredPersons: number) => {
      const newInsurePersons = [];
      for (let i = 0; i < numberInsuredPersons; i++) {
        newInsurePersons.push(insurancePersonDefaultData);
      }

      dispatch({
        type: MiteActionTypes.SetNumberInsuredPersons,
        payload: numberInsuredPersons,
      });

      dispatch({
        type: MiteActionTypes.SetInsuredPersons,
        payload: newInsurePersons,
      });
    },
    [numberInsuredPersons]
  );

  const handlePolicyChooseClick = (value: string, startDate: string) => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: 1,
    });

    dispatch({
      type: MiteActionTypes.SetStartDate,
      payload: convertDateFormatInTimeZone(new Date(startDate)),
    });

    dispatch({
      type: MiteActionTypes.SetChoosedPolicy,
      payload: value,
    });

    value === MitePolicyChoose.RELATIVES
      ? dispatch({
          type: MiteActionTypes.SetNumberInsuredPersons,
          payload: (insuredPersons.length >= 2 && insuredPersons.length) || 2,
        })
      : dispatch({
          type: MiteActionTypes.SetNumberInsuredPersons,
          payload: 1,
        });

    setIsPolicyClicked(true);
    sendAnalyticEvent(
      analyticEvents.miteSubmitApp,
      {},
      AnalyticsType.myTracker
    );
    sendAnalyticEvent(analyticEvents.miteToStepPrice);
  };

  useEffect(() => {
    if (!isLoading && res) {
      storeInsuranceProduct(res);
    }
  }, [isLoading, res]);

  useEffect(() => {
    storeNumberInsurePersons(numberInsuredPersons);
  }, [numberInsuredPersons]);

  useEffect(() => {
    isPolicyClicked && navigate('/mite-steps');
  }, [isPolicyClicked]);

  if (isLoading) return <Skeleton />;

  if (error) {
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
  }

  const cardMenuData = (card: Array<CardType>) => {
    return card.map(({ key, value, isLast }, idx: number) => (
      <StyledTarifCardList key={idx} isLast={isLast}>
        <StyledCardItem>
          <StyledCardListKey>{key}</StyledCardListKey>
          <StyledCardListValue isIcon={typeof value === 'object'}>
            {value}
          </StyledCardListValue>
        </StyledCardItem>
      </StyledTarifCardList>
    ));
  };

  const cardsElement = (
    <>
      {insuranceProductPolicy?.antimitePrograms?.map(
        (
          {
            name,
            maxNumObjects,
            basePrice,
            insuranceProgram,
            showByDefault,
            startDate,
          },
          idx
        ) => (
          <StyledCardBox
            active={
              choosedPolicy ? choosedPolicy === insuranceProgram : showByDefault
            }
            key={idx}
          >
            {choosedPolicy
              ? choosedPolicy === insuranceProgram && <StyledBorder />
              : showByDefault && <StyledBorder />}
            <StyledCardTitle>{name}</StyledCardTitle>
            <StyledCardSubTitle>
              {maxNumObjects > 1
                ? t('MITE_FORM:subLabels.personCount', {
                    personCount: maxNumObjects,
                  })
                : t('MITE_FORM:subLabels.person')}
            </StyledCardSubTitle>
            {cardMenuData(
              MITE_CHOOSE[insuranceProgram as keyof typeof MITE_CHOOSE].cardData
            )}
            <StyledButtonBox>
              <StyledButton
                onClick={() =>
                  handlePolicyChooseClick(insuranceProgram, startDate)
                }
              >
                {t('MITE_FORM:buttonLabel.price', {
                  price: parseFloat(basePrice),
                })}
              </StyledButton>
            </StyledButtonBox>
          </StyledCardBox>
        )
      )}
    </>
  );

  const swiperCardsElement = insuranceProductPolicy?.antimitePrograms?.map(
    (
      {
        name,
        maxNumObjects,
        basePrice,
        insuranceProgram,
        showByDefault,
        startDate,
      },
      idx
    ) => {
      return (
        <SwiperSlide key={idx}>
          <StyledCardBox active={showByDefault}>
            {showByDefault && <StyledBorder />}
            <StyledCardTitle>{name}</StyledCardTitle>
            <StyledCardSubTitle>
              {maxNumObjects > 1
                ? t('MITE_FORM:subLabels.personCount', {
                    personCount: maxNumObjects,
                  })
                : t('MITE_FORM:subLabels.person')}
            </StyledCardSubTitle>
            {cardMenuData(
              MITE_CHOOSE[insuranceProgram as keyof typeof MITE_CHOOSE].cardData
            )}
            <>
              <StyledPriceBox>
                <StyledSwiperPriceValue>
                  {t('COMMON:price.rubles', {
                    price: parseFloat(basePrice),
                  })}
                </StyledSwiperPriceValue>{' '}
                {t('MITE_FORM:forYear')}
              </StyledPriceBox>
              <StyledButtonBox>
                <StyledButton
                  onClick={() =>
                    handlePolicyChooseClick(insuranceProgram, startDate)
                  }
                >
                  {t('COMMON:hints.choose')}
                </StyledButton>
              </StyledButtonBox>
            </>
          </StyledCardBox>
        </SwiperSlide>
      );
    }
  );

  return (
    <BaseLayout>
      <Container>
        <AuthPageTitle>{t('COMMON:labels.choosePolicy')}</AuthPageTitle>
        {isDesktop ? (
          <StyledCardContainer>{cardsElement}</StyledCardContainer>
        ) : (
          <StyledSwiperReact
            initialSlide={1}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                centeredSlides: true,
                spaceBetween: 11,
              },
            }}
            pagination={{
              clickable: true,
            }}
          >
            <StyledCardContainer>{swiperCardsElement}</StyledCardContainer>
          </StyledSwiperReact>
        )}
      </Container>
    </BaseLayout>
  );
});
