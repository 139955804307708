/* eslint-disable indent */
import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  DocumentsIcon,
  RegulatorIcon,
  StarsIcon,
} from '@pulse-web-ui/colored-icons';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';
import { useTheme } from '@pulse-web-ui/theme';

import { PageSubTitle, PageTitle } from '@src/common-components/headers';
import {
  FormSub,
  FormSubLabel,
  GridColumnContainer,
  HeaderAdaptive4,
  UniversalColumn,
  UniversalColumnsWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';
import { useScrollToIframeOnMount } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';

export const BestToPayInProgressPage: FC = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const storedStartDate = localStorage.getItem('startDate');
  const [searchParams] = useSearchParams();
  const selectedProduct = localStorage.getItem('selectedProduct') as Product;
  const preset = localStorage.getItem('preset');

  const pageSubTitle = useMemo(() => {
    switch (selectedProduct) {
      case Product.PETS:
        return t('BEST_TO_PAY:hints.comingSoonToMobileApp');
      default:
        return t('BEST_TO_PAY:hints.usuallyItHappensInstantly');
    }
  }, [selectedProduct, t]);

  const appButtonLabel = useMemo(() => {
    switch (selectedProduct) {
      case Product.PETS:
        return t('COMMON:buttons.installApp');
      default:
        return t('COMMON:buttons.downloadApp');
    }
  }, [selectedProduct, t]);

  useEffect(() => {
    if (selectedProduct === Product.HOUSE && preset) {
      sendAnalyticEvent(analyticEvents.iflHousePresetPaymentSuccess);
    }
  }, []);

  useScrollToIframeOnMount(selectedProduct);

  useEffect(() => {
    if (searchParams.get('reference')) {
      localStorage.setItem(
        'referenceSuccess',
        String(searchParams.get('reference'))
      );
      localStorage.setItem(
        'operationId',
        String(searchParams.get('operation'))
      );
    }
  }, []);

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={24}>
        <UniversalColumn align="left" gap={0}>
          <PageTitle>{t('BEST_TO_PAY:headers.processingPayment')}</PageTitle>
          {storedStartDate ? (
            <PageSubTitle>
              {t('COMMON:labels.willStartToAct')}{' '}
              {new Date(storedStartDate).toLocaleDateString('ru', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </PageSubTitle>
          ) : null}
          <FormSubLabel>{pageSubTitle}</FormSubLabel>
        </UniversalColumn>
        <FormSub>
          {isBrowser ? (
            <DisplayQRButton buttonLabel={appButtonLabel} />
          ) : (
            <Button
              variant="primary"
              label={appButtonLabel}
              onClick={() =>
                window.open(
                  window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK,
                  '_blank'
                )
              }
            />
          )}
        </FormSub>
      </GridColumnContainer>
      <UniversalColumn align="left">
        <HeaderAdaptive4>
          {t('BEST_TO_PAY:headers.InApplicationYouCan')}
        </HeaderAdaptive4>
      </UniversalColumn>
      <UniversalColumnsWrapper>
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <StarsIcon />
          <div>{t('BEST_TO_PAY:hints.ReportInsuredEvent')}</div>
        </UniversalColumn>
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <RegulatorIcon />
          <div>{t('BEST_TO_PAY:hints.controlSubscription')}</div>
        </UniversalColumn>
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <DocumentsIcon />
          <div>{t('BEST_TO_PAY:hints.getUsefulMaterials')}</div>
        </UniversalColumn>
      </UniversalColumnsWrapper>
    </BaseLayout>
  );
};
