/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { useTheme } from '@pulse-web-ui/theme';

import {
  AdaptiveHeadersWrapper,
  Container,
  FormSubTitle,
  InfoWrapper,
  PaymentPeriodCardDescription,
  PaymentPeriodCardTitle,
  RadioDescriptionRender,
  RadioLabelRender,
  Skeleton,
  SubHeaderAdaptiveWarning,
  SubHeadersWrapper,
  WarnigText,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  DEFAULT_FIAS_ID,
  FeatureFlags,
  PRODUCT_VERSION_TWO,
  analyticEvents,
  durationDescription,
  insuranceProductsCode,
} from '@src/constants';
import {
  GlobalErrorInfo,
  PaymentPeriodHint,
  PaymentPeriodList,
  PaymentPeriodsTitle,
  PromoCodeDiscount,
} from '@src/features';
import {
  useBlockNextStep,
  useHandlePressKey,
  useNextStep,
  useRequests,
  useValidateProfileAuth,
} from '@src/hooks';
import {
  AuthActionTypes,
  AvailableSubscriptionActionTypes,
  CheckUserDataActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { IFLFlatActionTypes } from '@src/store/ifl-flat';
import { WizardActionTypes } from '@src/store/wizard';
import { FormPromoProps, KeyCode, SelectedDuration } from '@src/types';
import {
  addTestAttribute,
  checkDateBefore,
  checkValidDate,
  convertDateByTimeZone,
  createDateWithTimezone,
  getPaymentPeriodCardSubtitle,
  resetPromo,
  setPaymentPageTexts,
  subObjectConverter,
} from '@src/utils';

import {
  formInsurancePeriodDefaultValues,
  formInsurancePeriodSchema,
} from '../../schemas';
import {
  ContentWrapper,
  PromoSubmitButton,
  PromoTextInput,
  PromoWrapper,
} from './form-insurance-period.styles';
import {
  useFlatOrderArray,
  useIflFlatDraft,
  useIflOrderFlatData,
} from './hooks';

export const FormInsurancePeriod: FC = () => {
  const theme: any = useTheme();
  const [isInputActive, setIsInputActive] = useState(false);
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLFlat: {
        promoCode,
        promoCodeApplyed,
        risks,
        insuranceSum,
        insuranceSubproducts,
        selectedDuration,
        selectedRegion,
        selectedDate,
        selectedIProduct,
        getPricesFull,
        dadaValue,
      },
      stateAuth: { authTokens },
      stateUser: { profile, promoCodeFailedMessage, agentLogin },
      stateAuthFlow: { authFlowParams, authStep, sepScrAuth },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);

  const setFlatOrderArray = useFlatOrderArray();

  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormPromoProps>(
    formInsurancePeriodDefaultValues
  );
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [autoInitPromo, setAutoInitPromo] = useState(false);
  const [localPromo, setLocalPromo] = useState<string | undefined>();

  const apartmentSubscriptionTypeFlag = useMemo(
    () =>
      authFlowParams?.partnerAuthRegType === 'base'
        ? FeatureFlags.ApartmentSubscriptionType
        : (null as unknown as FeatureFlags),
    [authFlowParams?.partnerAuthRegType]
  );

  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  useBlockNextStep(profileIsLoading, profileError);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep5Period);
    sendAnalyticEvent(analyticEvents.iflFlatStep5Period);
  }, []);

  const navigate = useNavigate();
  const orderData = useIflOrderFlatData();
  useIflFlatDraft();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    getValues,
    watch,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      promoCode:
        promoCodeApplyed && getPricesFull?.isSuccessfulPromo
          ? promoCodeApplyed
          : promoCode,
    },
  });

  useEffect(() => {
    if (selectedDate !== (null || undefined)) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: IFLFlatActionTypes.SetPromoCode,
        payload: value.promoCode,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const { promoCode: promoCodeValue } = getValues();
  resetPromo(promoCodeValue, promoCodeApplyed, null, watch('promoCode'));

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    if (promoCodeApplyed && getPricesFull?.isSuccessfulPromo) {
      setLocalPromo(promoCodeApplyed);
      setAutoInitPromo(true);
    }
  }, []);

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: IFLFlatActionTypes.SetSelectedDate,
      payload: newDate,
    });
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
    sendAnalyticEvent(analyticEvents.dateStartRealty);
  };

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: IFLFlatActionTypes.SetSelectedDuration,
      payload: val,
    });

    sendAnalyticEvent(analyticEvents.periodSelectRealty, {
      period: durationDescription[val],
    });
  };

  const {
    isLoading,
    isError,
    res: [getPricesData, getInsuranceLimitStartData],
    refetchAll,
  } = useRequests([
    {
      key: 'formIFLFlatGetPricesFull',
      method: 'post',
      url: '/v1/subscription/get-prices',
      request: {
        risks: risksArray,
        productCode: selectedIProduct?.code,
        insuranceSum: Number(insuranceSum),
        personProperties: {
          subObjects:
            insuranceSubproducts?.subObjects &&
            subObjectConverter(insuranceSubproducts.subObjects),
          address: {
            regionCode: selectedRegion?.region,
            address: dadaValue?.value || '',
            addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
          },
        },
        returnMinDuration: false,
        promoCode: autoInitPromo ? localPromo : undefined,
      },
      deps: [
        risks,
        insuranceSubproducts,
        localPromo,
        autoInitPromo,
        dadaValue?.value,
        dadaValue?.data?.fias_id,
      ],
      accessToken: authTokens?.authorization?.accessToken,
    },
    {
      key: 'formIFLGetInsuranceLimitStart',
      method: 'get',
      url: `/v3/references/insurance-limit-start/${insuranceProductsCode.flat}/${PRODUCT_VERSION_TWO}`,
      deps: [risks],
    },
  ]);

  const validatePage = useCallback(() => {
    dispatch({
      type: CheckUserDataActionTypes.SetProductCode,
      payload: selectedIProduct?.code,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetRisks,
      payload: risks?.filter((riskItem) => riskItem.active),
    });
    dispatch({
      type: CheckUserDataActionTypes.SetSubobjects,
      payload: insuranceSubproducts?.subObjects,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetPromoCode,
      payload: autoInitPromo ? localPromo : undefined,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetAddress,
      payload: dadaValue?.value || '',
    });
    dispatch({
      type: CheckUserDataActionTypes.SetRegionCode,
      payload: selectedRegion?.region,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetAddressCode,
      payload: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetPrices,
      payload: getPricesData?.data?.prices,
    });
    dispatch({
      type: CheckUserDataActionTypes.SetSelectedDuration,
      payload: selectedDuration || undefined,
    });
    dispatch({
      type: AvailableSubscriptionActionTypes.SetOrderData,
      payload: orderData,
    });

    if (!profile?.profile.lastName) {
      profileRefetch().then((data) => {
        if (
          data.error?.response?.status !== 403 &&
          data.error?.response?.data?.code !== 'INVALID_ROLE'
        ) {
          navigate('/check-user-data');
        } else {
          navigate('/personal-info', {
            state: { nextRoute: '/check-user-data' },
          });
        }
      });
    } else {
      navigate('/check-user-data');
    }

    return false;
  }, [
    selectedIProduct?.code,
    risks,
    insuranceSubproducts?.subObjects,
    autoInitPromo,
    localPromo,
    dadaValue?.value,
    dadaValue?.data?.fias_id,
    selectedRegion?.region,
    getPricesData?.data?.prices,
    selectedDuration,
    orderData,
  ]);

  useNextStep(validatePage);
  useIflFlatDraft();

  useEffect(() => {
    if (
      !checkValidDate(selectedDate) &&
      !isError &&
      getInsuranceLimitStartData?.data
    ) {
      setSelectedDate(
        createDateWithTimezone(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [!isLoading && getInsuranceLimitStartData, dateEqual]);

  useEffect(() => {
    if (
      !isError &&
      selectedDate &&
      getInsuranceLimitStartData &&
      (checkDateBefore(
        new Date(selectedDate),
        new Date(getInsuranceLimitStartData.data?.startDate)
      ) ||
        checkDateBefore(
          new Date(getInsuranceLimitStartData.data?.endDate),
          new Date(selectedDate)
        ) ||
        dateEqual)
    ) {
      setSelectedDate(
        createDateWithTimezone(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [!isLoading && getInsuranceLimitStartData.data, dateEqual]);

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });

      setIsInputActive(false);
    }
  }, [isLoading]);

  const handleKeyPressEnter = () => {
    if (isInputActive) {
      submitPromoCode();
    } else if (currentStep !== authStep || sepScrAuth) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [isInputActive]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: t('IFL_FLAT_FORM:headers.pulseApartment') || '',
    });
  }, []);

  useEffect(() => {
    setPaymentPageTexts(
      t('COMMON:hints.somethingHappensApartment'),
      checkValidDate(selectedDate) ? selectedDate : null
    );

    setFlatOrderArray(getPricesData?.data);
  }, [isLoading, getPricesData?.data, setFlatOrderArray, selectedDate]);

  useEffect(() => {
    if (!isLoading && getPricesData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: IFLFlatActionTypes.SetGetPricesFull,
        payload: getPricesData.data,
      });

      if (!selectedDuration) {
        if (authFlowParams?.allowedPeriod) {
          dispatch({
            type: IFLFlatActionTypes.SetSelectedDuration,
            payload: authFlowParams.allowedPeriod,
          });
        } else if (getPricesData?.data?.defaultContractDuration) {
          dispatch({
            type: IFLFlatActionTypes.SetSelectedDuration,
            payload: getPricesData?.data?.defaultContractDuration,
          });
        } else {
          dispatch({
            type: IFLFlatActionTypes.SetSelectedDuration,
            payload: getPricesData?.data?.prices[0]?.duration,
          });
        }
      }

      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        formData.promoCode !== ''
      ) {
        setError('promoCode', {
          type: 'string',
          message: 'COMMON:errors.promoCodeNotValid',
        });

        dispatch({
          type: UserActionTypes.SetPromoCodeFailedMessage,
          payload: t('COMMON:errors.promoCodeNotValid') || '',
        });
      }

      const isPromoCodeAppliedSuccessfully =
        getPricesData.data?.isSuccessfulPromo && promoCode !== '';

      let isButtonHasToBeDisabled = true;

      if (isPromoCodeAppliedSuccessfully) {
        isButtonHasToBeDisabled = true;
      }

      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        promoCode !== ''
      ) {
        isButtonHasToBeDisabled = false;
      }

      if (
        getPricesData?.data?.isSuccessfulPromo === undefined &&
        promoCode !== '' &&
        !autoInitPromo
      ) {
        isButtonHasToBeDisabled = false;
      }
      if (
        getPricesData?.data?.isSuccessfulPromo &&
        promoCode !== '' &&
        !promoCodeApplyed
      ) {
        dispatch({
          type: IFLFlatActionTypes.SetPromoCodeApplyed,
          payload: promoCode,
        });
      }

      setSubmitPromoDisabled(isButtonHasToBeDisabled);
    }
  }, [isLoading, getPricesData.data]);

  useEffect(() => {
    if (!isLoading && getInsuranceLimitStartData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      setMinDate(new Date(getInsuranceLimitStartData.data?.startDate));
      setMaxDate(new Date(getInsuranceLimitStartData.data?.endDate));
      if (
        JSON.stringify(getInsuranceLimitStartData.data?.startDate) ===
        JSON.stringify(getInsuranceLimitStartData.data?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        createDateWithTimezone(
          selectedDate || getInsuranceLimitStartData.data?.startDate
        )
      );
    }
  }, [isLoading, getInsuranceLimitStartData.data]);

  useEffect(() => {
    if (authTokens?.authorization?.accessToken) {
      reset();
    }
  }, [authTokens?.authorization?.accessToken]);

  useEffect(() => {
    if (getPricesData?.data?.isSuccessfulPromo === false && promoCode !== '') {
      setError('promoCode', {
        type: 'string',
        message: 'COMMON:errors.promoCodeNotValid',
      });
      dispatch({
        type: IFLFlatActionTypes.SetPromoCodeApplyed,
        payload: undefined,
      });
    }
  }, [isLoading, getPricesData?.data, promoCode]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const submitPromoCode = handleSubmit((data) => {
    dispatch({
      type: IFLFlatActionTypes.SetPromoCode,
      payload: data.promoCode,
    });

    dispatch({
      type: UserActionTypes.SetPromoCodeFailedMessage,
      payload: undefined,
    });

    dispatch({
      type: IFLFlatActionTypes.SetPromoCodeApplyed,
      payload: undefined,
    });

    setFormData(data);
    setAutoInitPromo(true);
    setLocalPromo(promoCode);
  });

  const getLabel = () => {
    const { promoCode: statePromoCode } = getValues();
    const isPromoCodeAppliedSuccessfully =
      getPricesData?.data?.isSuccessfulPromo;

    return isPromoCodeAppliedSuccessfully && promoCode === statePromoCode
      ? t('COMMON:success.applied')
      : t('COMMON:success.apply');
  };

  const pricesByPeriod = useMemo(() => {
    if (!getPricesFull?.prices) {
      return [];
    }

    if (authFlowParams?.allowedPeriod) {
      return getPricesFull.prices.filter(
        (pricesByPeriod) =>
          pricesByPeriod.duration === authFlowParams.allowedPeriod
      );
    }

    return getPricesFull.prices;
  }, [getPricesFull, authFlowParams]);

  const isOnlyOnePeriodAllowed = useMemo(() => {
    return pricesByPeriod.length === 1;
  }, [pricesByPeriod]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isLoading || profileIsLoading,
    });
  }, [isLoading, profileIsLoading]);

  if (isLoading || profileIsLoading) return <Skeleton />;

  if (isError) {
    const e = (isError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration') || '',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }
    return <GlobalErrorInfo retrayHandler={refetchAll} />;
  }

  if (profileError) {
    return (
      <GlobalErrorInfo
        pending={profileIsLoading}
        retrayHandler={profileRefetch}
      />
    );
  }

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <PaymentPeriodsTitle
          featureFlag={apartmentSubscriptionTypeFlag}
          marginBottom={0}
          isOnlyOnePeriodAllowed={isOnlyOnePeriodAllowed}
        />
        {agentLogin && (
          <SubHeadersWrapper>
            <SubHeaderAdaptiveWarning>
              {t('COMMON:hints.priceMayChange')}
            </SubHeaderAdaptiveWarning>
          </SubHeadersWrapper>
        )}
      </AdaptiveHeadersWrapper>

      <PaymentPeriodList
        onChange={onRadioSelector}
        selectedDuration={selectedDuration}
        prices={pricesByPeriod}
        isOnlyOnePeriodAllowed={isOnlyOnePeriodAllowed}
        getLabel={(price, isFeatureSubscriptionType) =>
          isFeatureSubscriptionType ? (
            <PaymentPeriodCardTitle
              premiumAndDelta={price.premiumAndDelta}
              premiumAndDeltaPromo={price.premiumAndDeltaPromo}
            />
          ) : (
            RadioLabelRender(price)
          )
        }
        getDescription={(price, isFeatureSubscriptionType) => {
          if (isOnlyOnePeriodAllowed) {
            return;
          }

          return isFeatureSubscriptionType ? (
            <PaymentPeriodCardDescription duration={price.duration} />
          ) : (
            RadioDescriptionRender(price)
          );
        }}
        getSubtitle={(price) => getPaymentPeriodCardSubtitle(price.duration)}
        featureFlag={apartmentSubscriptionTypeFlag}
      />

      <ContentWrapper>
        <PromoWrapper>
          <PromoTextInput>
            <Controller
              control={control}
              name="promoCode"
              render={({ field: { onChange, value }, fieldState }) => (
                <HelperText
                  status={
                    fieldState.error || promoCodeFailedMessage
                      ? 'error'
                      : 'success'
                  }
                  message={
                    (errors.promoCode?.message &&
                      t(errors.promoCode.message)) ||
                    promoCodeFailedMessage ||
                    (getPricesData.data?.isSuccessfulPromo &&
                    promoCode !== '' &&
                    getPricesFull?.prices ? (
                      <PromoCodeDiscount
                        prices={getPricesFull?.prices}
                        featureFlag={FeatureFlags.ApartmentDiscount}
                      />
                    ) : (
                      ''
                    ))
                  }
                  testId="apartment-form-promocode-error"
                >
                  <Input
                    label={t('COMMON:labels.promoCode') || ''}
                    value={value}
                    onChange={(val) => {
                      checkSubmitState(val);
                      return onChange(val);
                    }}
                    error={!!errors.promoCode || !!promoCodeFailedMessage}
                    onFocus={() => setIsInputActive(true)}
                    onBlur={() => setIsInputActive(false)}
                    disabled={isLoading}
                    {...addTestAttribute('apartment-form-promocode')}
                  />
                </HelperText>
              )}
            />
          </PromoTextInput>
          <PromoSubmitButton>
            <Button
              label={getLabel()}
              onClick={submitPromoCode}
              disabled={submitPromoDisabled}
              variant="secondary-2"
              adaptiveWidth
              {...addTestAttribute('apartment-form-promocode-button-apply')}
            />
          </PromoSubmitButton>
        </PromoWrapper>
      </ContentWrapper>

      <FormSubTitle>{t('COMMON:labels.subscriptionActive')}</FormSubTitle>
      <HelperText
        message={
          !isLoading &&
          !dateEqual && (
            <PaymentPeriodHint
              featureFlag={apartmentSubscriptionTypeFlag}
              minDate={minDate}
              maxDate={maxDate}
            />
          )
        }
        status="default"
        messagePosition="down"
        testId="apartment-form-subscription-start-date-error"
      >
        {isLoading ? (
          <Skeleton />
        ) : dateEqual ? (
          <InfoWrapper>
            <Info width={24} color={theme.colors.icon.primary} />
            <WarnigText
              {...addTestAttribute('apartment-form-subscription-start-message')}
            >
              {t('COMMON:labels.subscriptionWillStart')}{' '}
              {minDate.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </WarnigText>
          </InfoWrapper>
        ) : (
          <Datepicker
            onChange={setSelectedDate}
            selected={checkValidDate(selectedDate) ? selectedDate : null}
            minDate={convertDateByTimeZone(minDate)}
            maxDate={convertDateByTimeZone(maxDate)}
            {...addTestAttribute('apartment-form-subscription-start')}
          />
        )}
      </HelperText>
    </Container>
  );
};
