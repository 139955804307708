/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { SelectorCard } from '@pulse-web-ui/selector-card';

import {
  AdaptiveListWrapper,
  Container,
  FormLabel,
  FormSub,
  LinkContainer,
  RiskWrapper,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep, useRequest } from '@src/hooks';
import { IFLFlatActionTypes, Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import { addTestAttribute, evenRisk } from '@src/utils';

import { useIflFlatDraft } from './hooks';

export const FormInsuranceRisks: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: {
      stateFormIFLFlat: { risks, selectedIProduct },
    },
    dispatch,
  } = useContext(Store);

  const [listBoxStatus, setListBoxStatus] = useState<'error' | undefined>(
    undefined
  );

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep2Risk);
    sendAnalyticEvent(analyticEvents.iflFlatStep2Risk);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const handleKeyPressEnter = () => {
    validatePage();
    if (listBoxStatus !== 'error') {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const { isLoading, error, res, refetch } = useRequest(
    'formIFLGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
    },
    [],
    true
  );

  useEffect(() => {
    refetch();
  }, []);

  const validatePage = useCallback(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !risks?.some(evenRisk) || false,
    });

    setListBoxStatus(!risks?.some(evenRisk) ? 'error' : undefined);

    return risks?.some(evenRisk) || false;
  }, [risks]);

  useNextStep(validatePage);
  useIflFlatDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: listBoxStatus === 'error',
    });
  }, [listBoxStatus]);

  useEffect(() => {
    if (!isLoading && res && res.risks.length > 0 && !risks) {
      dispatch({
        type: IFLFlatActionTypes.SetRisks,
        payload: res.risks,
      });
    }
  }, [isLoading, res, risks]);

  useEffect(() => {
    validatePage();
  }, [risks]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetIsPageLoading,
      payload: isLoading,
    });
  }, [isLoading]);

  if (isLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  const onClick = (code: string) => {
    setStepUpdated();

    dispatch({
      type: IFLFlatActionTypes.SetInsuranceSubproducts,
      payload: undefined,
    });

    setListBoxStatus(undefined);

    const newRisks = risks?.map((item) => {
      if (item.code === code) {
        sendAnalyticEvent(
          item.active
            ? analyticEvents.deselectOptionRealty
            : analyticEvents.selectOptionRealty,
          {
            option: item.name,
          }
        );

        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });

    dispatch({
      type: IFLFlatActionTypes.SetRisks,
      payload: newRisks,
    });

    dispatch({
      type: IFLFlatActionTypes.SetGetSubobjectsRefetchUid,
      payload: uuidv4(),
    });

    dispatch({
      type: IFLFlatActionTypes.SetEmptyDependencies,
      payload: [],
    });

    dispatch({
      type: IFLFlatActionTypes.SetFieldWithEmptyDependencies,
      payload: undefined,
    });
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: IFLFlatActionTypes.SetRisksPage,
      payload: 'primary',
    });
    navigate('/flat-risk-descriptions');
  };

  return (
    <Container>
      <FormLabel>{t('COMMON:headers.youChooseWePay')}</FormLabel>
      <FormSub isError={listBoxStatus === 'error'}>
        {t('COMMON:hints.risksToReceivePayment')}
      </FormSub>
      <AdaptiveListWrapper>
        {(!risks || risks?.length === 0) && t('COMMON:errors.noData')}
        {risks?.map((item) => {
          return item.binding === true ? (
            <RiskWrapper>
              <SelectorCard
                key={item.code}
                id={item.code}
                mobileFullWidth
                name={item.code}
                label={item.name}
                description={item.description}
                checked={item.active}
                readOnly={!item.switchability}
                onClick={() => onClick(item.code)}
                testId="apartment-form-main-risks"
              />
            </RiskWrapper>
          ) : (
            <></>
          );
        })}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutMainRisks') || ''}
          {...addTestAttribute('apartment-form-risks-button-about')}
        />
      </LinkContainer>
    </Container>
  );
};
