import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

const sendEvent = (event: string, presetEvent: string, isPreset: boolean) => {
  isPreset ? sendAnalyticEvent(presetEvent) : sendAnalyticEvent(event);
};

export const handleRegistrationStart = (
  product: Product,
  isPreset: boolean
) => {
  const registrationStartHandler = {
    [Product.APARTMENT]: () => {
      sendAnalyticEvent(analyticEvents.authFlatRegistrationStart);
    },
    [Product.HOUSE]: () => {
      sendEvent(
        analyticEvents.authHouseRegistrationStart,
        analyticEvents.authHouseRegistrationStartPreset,
        isPreset
      );
    },
    [Product.NS]: () => {
      sendAnalyticEvent(analyticEvents.authNSRegistrationStart);
    },
    [Product.PETS]: () => {
      sendEvent(
        analyticEvents.authPetRegistrationStart,
        analyticEvents.authPetRegistrationStartPreset,
        isPreset
      );
    },
    [Product.SPORT]: () => {
      sendAnalyticEvent(analyticEvents.authSportRegistrationStart);
    },
    [Product.MITE]: () => {
      sendAnalyticEvent(analyticEvents.authMiteRegistrationStart);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    [Product.TRAVEL]: () => {},
  };

  registrationStartHandler[product]();
};
