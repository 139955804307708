import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useIflFlatDraft = () => {
  const {
    state: {
      stateFormIFLFlat: {
        selectedIProduct,
        selectedRegion,
        regions,
        risks,
        insuranceSubproducts,
        insuranceSum,
        getPrices,
        selectedDuration,
        selectedDate,
        dadaValue,
        promoCode,
        promoCodeApplyed,
        risksPage,
        fieldWithEmptyDependencies,
        emptyDependencies,
        isObjectDataError,
        newSubobjects,
        isSelectedCustomStreet,
        isSelectedCustomHouse,
        isEmptyStreetSuggestions,
        isEmptyHouseSuggestions,
        isHouseNotFound,
      },
      stateFormIFLFlat,
      stateUser: { profile, selectedProduct, agentLogin },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (!agentLogin) {
      const data = {
        dataObject: {
          ...stateFormIFLFlat,
          promoCode: promoCodeApplyed && promoCode,
        },
        profile,
        selectedProduct,
        currentStep: currentStep || 0,
      };

      setDraftLS(data);
    }
  }, [
    selectedIProduct,
    selectedRegion,
    regions,
    risks,
    insuranceSubproducts,
    insuranceSum,
    getPrices,
    selectedDuration,
    selectedDate,
    dadaValue,
    promoCode,
    promoCodeApplyed,
    risksPage,
    fieldWithEmptyDependencies,
    emptyDependencies,
    isObjectDataError,
    newSubobjects,
    isSelectedCustomStreet,
    isSelectedCustomHouse,
    isEmptyStreetSuggestions,
    isEmptyHouseSuggestions,
    agentLogin,
    isHouseNotFound,
  ]);

  return;
};
