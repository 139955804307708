import { authNS, authNamespaces } from './authorization';
import {
  availableSubscriptionNS,
  availableSubscriptionNamespaces,
} from './available-subscription';
import { bestToPayNS, bestToPayNamespaces } from './best-to-pay';
import { checkUserDataNS, checkUserDataNamespaces } from './check-user-data';
import { commonNS, commonNamespaces } from './common-ns';
import { iflFlatFormNS, iflFlatFormNamespaces } from './ifl-flat-form';
import { iflHouseFormNS, iflHouseFormNamespaces } from './ilf-house-form';
import { insuranceFormsNS, insuranceFormsNamespaces } from './insurance-forms';
import { miteForm, miteFormNamespaces } from './mite';
import { nsFormNS, nsFormNamespaces } from './ns-form';
import { orderNS, orderNamespaces } from './order';
import { petsFormNS, petsNamespaces } from './pets-form-ns';
import { risksAccordionNS, risksAccordionNamespaces } from './risks-accordion';
import { scoreErrorNamespaces, scoreErrormNS } from './score-error';
import { sportFormNS, sportFormNamespaces } from './sport-form';
import { vipNS, vipNamespaces } from './vip';

export const resources = {
  ru: {
    ...commonNS,
    ...petsFormNS,
    ...iflFlatFormNS,
    ...iflHouseFormNS,
    ...insuranceFormsNS,
    ...nsFormNS,
    ...bestToPayNS,
    ...orderNS,
    ...authNS,
    ...scoreErrormNS,
    ...vipNS,
    ...miteForm,
    ...risksAccordionNS,
    ...sportFormNS,
    ...availableSubscriptionNS,
    ...checkUserDataNS,
  },
};

export const namespaces = [
  ...commonNamespaces,
  ...petsNamespaces,
  ...iflFlatFormNamespaces,
  ...iflHouseFormNamespaces,
  ...insuranceFormsNamespaces,
  ...nsFormNamespaces,
  ...bestToPayNamespaces,
  ...orderNamespaces,
  ...authNamespaces,
  ...scoreErrorNamespaces,
  ...vipNamespaces,
  ...miteFormNamespaces,
  ...risksAccordionNamespaces,
  ...sportFormNamespaces,
  ...availableSubscriptionNamespaces,
  ...checkUserDataNamespaces,
];
