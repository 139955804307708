/* eslint-disable prettier/prettier */
import { useNavigate } from 'react-router-dom';

import { Skeleton } from '@src/components';
import { GlobalErrorInfo } from '@src/features';
import { useStartPath } from '@src/hooks';
import { SubscriptionChoice } from '@src/pages/authorization';

export const RoutesNavigate = () => {
  const navigate = useNavigate();
  const { isLoading, hasError, path } = useStartPath();

  if (isLoading) {
    return <Skeleton />;
  }

  if (path) {
    navigate(path);
  }

  if (hasError) {
    return <GlobalErrorInfo />;
  }

  return <SubscriptionChoice />;
};
