import { AnalyticsType, sendAnalyticEvent } from '@src/components/web-analytic';
import { analyticEvents } from '@src/constants';
import { HousePresetData } from '@src/store/ifl-house';
import { PetsPresetData } from '@src/store/pets';

export interface NavigateToPaymentAnalyticsEventsProps {
  authorizeRefRoute?: string;
  presetData?: PetsPresetData | HousePresetData;
}

export const handleNavigateToPaymentAnalyticsEvents = ({
  authorizeRefRoute,
  presetData,
}: NavigateToPaymentAnalyticsEventsProps): void => {
  {
    const selectedProduct = authorizeRefRoute?.slice(1);
    const productsEvents: Record<string, () => void> = {
      house() {
        if (!!presetData) {
          sendAnalyticEvent(analyticEvents.iflHousePresetStep5Pay);
        } else {
          sendAnalyticEvent(analyticEvents.iflStep9Pay);
          sendAnalyticEvent(analyticEvents.iflHouseStep9Pay);
        }
      },
      apartment() {
        sendAnalyticEvent(analyticEvents.iflStep9Pay);
        sendAnalyticEvent(analyticEvents.iflFlatStep9Pay);
        sendAnalyticEvent(
          analyticEvents.flatToStep9Pay,
          undefined,
          AnalyticsType.adriver
        );
      },
      ns() {
        sendAnalyticEvent(analyticEvents.toPaymentAccident);
      },
      pets() {
        sendAnalyticEvent(
          analyticEvents.petToStepPayAd,
          undefined,
          AnalyticsType.adriver
        );
        const event = !!presetData
          ? analyticEvents.petToStepPayPreset
          : analyticEvents.petToStepPay;
        sendAnalyticEvent(event);
      },
      ['sport-ns']() {
        sendAnalyticEvent(analyticEvents.sportToStepPay);
        sendAnalyticEvent(
          analyticEvents.sportToStepPayAd,
          undefined,
          AnalyticsType.adriver
        );
      },
      mite() {
        sendAnalyticEvent(analyticEvents.miteToStepPay);
        sendAnalyticEvent(
          analyticEvents.miteToStepPayAd,
          undefined,
          AnalyticsType.adriver
        );
      },
    };

    if (authorizeRefRoute) {
      productsEvents[selectedProduct as string]?.();
    }
  }
};
